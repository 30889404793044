import React, { useEffect, useState, useContext } from "react";
import ListCoinHeader from "src/components/HeaderFilterInput/ListCoin";
import TheHeaderDefault from "src/Layout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import GetApi from "src/api/ListCoinApi";
import DataTable from "src/components/DataTable";
import { keyHeaderFilter } from "src/util/data";
import Tooltip from "@mui/material/Tooltip";
import celebBadge from "src/assets/img/celeb_badge.svg";
import { convertTimeZone } from "src/util/convert";
import { checkTypeCoin, getAgeUser } from "src/util/Utils";
import { LoadingContext } from "src/providers/LoadingProvider";
import { showError } from "src/util/error";

const API = GetApi;
const ListCoin = () => {
  const agency = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null;
  const [items, setItems] = useState([]);
  const [total, settotal] = useState(0);
  const [filter, setfilter] = useState({
    // user_type: "CELEBRITY",
    // register_status: "ACCEPT",
    // agency_id: agency?.id || null,
  });
  const arr = [];
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const filterState = useFilters();
  const { LIMITED, currentPage, spages } = filterState;
  const handleMoneyGet = ({ donated_money, intermediary_fee }) => {
    const donated = Math.floor(donated_money / 10000) * 10000;
    let value;
    if (donated_money >= 50000) {
      value = (donated * intermediary_fee) / 100;
    } else {
      value = 0;
    }
    return value.toLocaleString();
  };
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nickname",
      headerName: "셀럽 닉네임(ID)",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>
              {params.row.nickname}{" "}
              <span>
                <img src={celebBadge} />
              </span>
            </p>
            <p>({params.row.identified_id})</p>
          </div>
        );
      },
    },
    {
      field: "birthday",
      headerName: "나이",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{getAgeUser(params.row.birthday)}</p>
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "성별",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.gender == "MALE" ? "남자" : "여자"}</p>
          </div>
        );
      },
    },
    {
      field: "total_fee",
      headerName: "변동금액",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "wallet",
      headerName: "변동 후 금액",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "money_can_get",
      headerName: "출금 가능 금액",
      flex: 1,
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div>
            {handleMoneyGet({
              donated_money: params?.row?.donated_money,
              intermediary_fee: params?.row?.intermediary_fee,
            })}
          </div>
        );
      },
    },
    {
      field: "type",
      headerName: "사유",
      flex: 1,
      minWidth: 250,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "sender_nickname",
      headerName: "회원 닉네임(ID)",
      minWidth: 250,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.sender_nickname} </p>
            <p>({params.row.sender_identified_id})</p>
          </div>
        );
      },
    },
    {
      field: "created_at_unix_timestamp",
      headerName: "변동날짜",
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.created_at_unix_timestamp}>
              <p>{params.row.created_at_unix_timestamp}</p>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const handleChangePage = (e, value) => {
    currentPage.set(value);
  };

  const fetchAll = async () => {
    showLoading();
    try {
      const response = await API.getAll(
        currentPage.value,
        LIMITED.value,
        JSON.stringify({
          type: {
            $notIn: ["SUBSCRIPTION", "ADMIN_SUB_STAR", "ADMIN_ADD_STAR"],
          },
          ...filter,
          "$receiver.agency_id$": agency?.id || null,
        })
      );

      // console.log("PostList -> response", response.results.objects.rows);
      settotal(response?.results?.objects.count);
      if (response.code === 200) {
        // xu li pagination
        let pages = Math.max(Math.ceil(response.results.objects.count / LIMITED.value), 1);
        spages.set(pages);

        response.results.objects.rows.map((val, idx) => {
          arr.push({
            id: val?.id,
            no: idx + (currentPage.value - 1) * LIMITED.value + 1,
            avatar: val?.receiver?.avatar,
            nickname: val?.receiver?.nickname || "-",
            gender: val?.receiver?.sex || "",
            phone: val?.receiver?.phone ? `${val?.receiver?.phone}` : "-",
            image_card: val?.receiver?.image_card,
            state_register: val?.receiver?.state_register ?? "",
            realname: val?.receiver?.name || "-",
            agency_code: val?.receiver?.agency_code || "-",
            identified_id: val?.receiver?.identified_id || "-",
            age_verified: val?.receiver?.verified || "-",
            status: val?.receiver?.state,
            total_fee: (val?.total_fee * 200).toLocaleString() || 0,
            wallet: Number.parseInt(val?.current_wallet_receiver || 0).toLocaleString(),
            status_account: val?.receiver?.status_account || "-",
            intermediary_fee: val?.receiver?.intermediary_fee || 0,
            hasgtag: val?.receiver?.hasgtag || "-",
            type: checkTypeCoin(val?.type, val?.call_history?.end_time, val?.call_history?.start_time),
            sender_nickname: val?.sender?.nickname || "-",
            sender_identified_id: val?.sender?.identified_id || "-",
            donated_money: val?.current_wallet_receiver || 0,
            user_type: val?.receiver?.user_type,
            subscribeCount: val?.receiver?.subscribers_count || 0,
            follower_count: val?.receiver?.follower_count || 0,
            email: val?.receiver?.email || "",
            birthday: convertTimeZone(parseInt(val?.receiver?.birthday) || val?.receiver?.created_at_unix_timestamp),

            created_at_unix_timestamp: convertTimeZone(+val?.created_at_unix_timestamp),
            processing_date: val?.receiver?.last_online ? convertTimeZone(parseInt(val?.receiver?.last_online)) : "-",
          });
        });
      }
    } catch (error) {
      hideLoading();
      showError(error);
    } finally {
      hideLoading();
      setItems(arr);
    }
  };

  useEffect(() => {
    fetchAll();
  }, [currentPage.value, LIMITED.value, filter]);

  return (
    <div>
      <TheHeaderDefault
        Component={ListCoinHeader}
        setFilter={setfilter}
        typeHeadeFilter={keyHeaderFilter.LIST_COIN}
        initialValues={{
          time__data: [],
          memberInfoInput: "",
          status_account: "ALL",
          memberInfoSelect: "$receiver.nickname$",
          type: "ALL",
        }}
      />
      <DataTable
        columns={columns}
        total={total}
        items={items}
        page={spages.value}
        currentPage={currentPage.value}
        changePage={handleChangePage}
        limit={LIMITED}
        fileName="list coin"
      />
    </div>
  );
};

export default ListCoin;
