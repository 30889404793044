import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields =
  'fields=["$all","$paranoid",{"agency":["$all"]},{"user_setting":["$all"]},{"user_hashtags":["hashtag_id",{"hashtag":["title"]}]}]';
const fields2 = 'fields=["$all"]';
const table = "user";

const Api = {
  getAll: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["agency_member_no","desc"]]`;
    return axiosClient.get(url);
  },
  getAll2: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}?${fields2}&filter=${filter}&page=${page}&limit=${limit}&order=[["agency_member_no","asc"]]`;
    return axiosClient.get(url);
  },
  getOne: (id) => {
    const url = `${HOSTNAME}/user/${id}/admin?fields=["$all",{"nice_infos":["$all"]},{"user_hashtags":["id",{"hashtag":["title"]}]},{"sales_histories":["$all",{"pack":["price"]}]},{"agency":["nickname","username"]},{"user_setting":["$all"]},{"penalty":["$all"]}]`;
    return axiosClient.get(url);
  },
  getUserSaleHistory: (filter, page, limit) => {
    const url = `${HOSTNAME}/sales_history/admin/get_list_cms?fields=["$all",{"user":["$all"]},{"pack":["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    return axiosClient.get(url);
  },
  getUserUseStarHistory: (filter, page, limit) => {
    const url = `${HOSTNAME}/usage_star/admin/get_list_cms?fields=["$all",{"sender":["$all"]},{"post":["$all",{"secret_album" : ["$all"]}]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    return axiosClient.get(url);
  },
  getUserUsageStarPost: (filter, filter2, filter3, page, limit) => {
    let url;
    let filterField2 = "";
    let filterField3 = "";
    if (filter?.secret_album) {
      filterField2 = `,{"$filter":${JSON.stringify(filter?.secret_album)}}`;
      delete filter?.secret_album;
    }
    if (filter?.sender) {
      filterField3 = `,{"$filter":${JSON.stringify(filter?.sender)}}`;
      delete filter?.sender;
    }
    url = `${HOSTNAME}/post/admin/get_list_cms?fields=["$all",{"usage_star_post":["id","created_at_unix_timestamp",{"sender":["$all"${filterField3}]}]},{"secret_album": ["$all"${filterField2}]}]&filter=${JSON.stringify(
      filter
    )}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    // if (filter2) {

    // } else {
    //   url = `${HOSTNAME}/post/admin/get_list_cms?fields=["$all",{"usage_star_post":["id","created_at_unix_timestamp",{"sender":["$all"]}]},{"secret_album": ["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    // }

    return axiosClient.get(url);
  },
  getUserFollow: (filter, page, limit) => {
    const url = `${HOSTNAME}/favorite/admin/get_list_cms?fields=["$all",{"followed":["$all"]},{"user":["id","identified_id","nickname","sex"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[[%22created_at%22,%22desc%22]]`;
    return axiosClient.get(url);
  },
  getUserSubcription: (filter, page, limit) => {
    const url = `${HOSTNAME}/subscriber/admin/get_list_cms?fields=["$all",{"celeb":["$all"]},{"user" : ["nickname","identified_id","sex"]}, {"pack_sub": ["$all", {"celeb": ["id", "nickname", "avatar","sex","phone","identified_id",{"agency":["$all"]}]}]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    return axiosClient.get(url);
  },
  getUserInquiry: (id = "ca518d00-3d7a-11ed-aa7d-e592f3e1c237") => {
    const url = `${HOSTNAME}/inquiry/admin/get_list_cms?fields=["$all",{"employee" : ["fullname","username"]},{"user" : ["nickname","identified_id","user_type"]}]&filter={"user_id":"${id}"}&page=1&limit=10&order=[["updated_at", "desc"]]`;
    return axiosClient.get(url);
  },
  //https://server.fansome.co.kr:5000/api/v1/usage_star/admin/get_list_cms?fields=["$all",{"sender":["$all"]},{"post":["$all",{"secret_album" : ["$all"]}]}]&filter={"user_id":"01931db0-8024-11ed-a3a0-31b8985b683d"}&page=1&limit=10&order=[["created_at","desc"]]

  // update: (body, id) => {
  //   const url = `${HOSTNAME}/${table}/${id}`;
  //   console.log("url...", url);
  //   return axiosClient.put(url, body, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // },
};

export default Api;
