import { Button, Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import { convertTextToKo, convertTimeZone } from "src/util/convert";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { headerFilter } from "src/util/data";
import FieldCustom from "./Fields/FieldCustom";
import InputDateRangerCustome from "./Fields/InputDateRangerCustome";
import { filterUsageStar } from "src/util/setFilter";
const DetailUsageStar = ({ id }) => {
  const { state_revenue, reason } = headerFilter?.StarUserProfile;
  const API = GetApi;
  const { register, handleSubmit, setValue } = useForm();
  const [item, setItem] = useState([]);
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      flex: 1,
      sortable: false,
    },
    {
      field: "state_revenue",
      headerName: "입출",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        if (params?.row?.reason?.includes("ADMIN")) {
          return (
            <>
              {params.row.reason === "ADMIN_ADD_STAR" ? (
                <p style={{ color: "#236DFF" }}>IN</p>
              ) : (
                <p style={{ color: "#FD5257" }}>OUT</p>
              )}
            </>
          );
        } else
          return (
            <>
              {params.row.receiver_id === id ? (
                <p style={{ color: "#236DFF" }}>IN</p>
              ) : (
                <p style={{ color: "#FD5257" }}>OUT</p>
              )}
            </>
          );
      },
    },
    {
      field: "reason",
      headerName: "사유",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return <p>{convertTextToKo(params?.row?.reason)}</p>;
      },
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "update_time",
      headerName: "변동시간",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
    {
      field: "star",
      headerName: "변동스타",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
    {
      field: "total_star",
      headerName: "잔액",
      flex: 1,

      headerAlign: "center",
      sortable: false,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    user_id: id,
  });
  const [dataDetail, setDataDetail] = useState();
  const getDetailUserUseStarHistory = async () => {
    let arr = [];

    try {
      const result = await API.getUserUseStarHistory(
        JSON.stringify({ ...filter }),
        currentPage,
        limit
      );
      setTotal(result?.results?.objects?.count);
      setPage(Math.max(Math.ceil(result.results.objects.count / limit), 1));
      result?.results?.objects?.rows.map((val, idx) => {
        return arr.push({
          id: val?.id,
          receiver_id: val?.receiver_id,
          no: idx + (currentPage - 1) * limit + 1,
          reason: val?.type,
          update_time: convertTimeZone(+val?.created_at_unix_timestamp),
          star:
            val?.type === "SUBSCRIPTION"
              ? "-"
              : val?.total_fee.toLocaleString(),
          total_star: (val?.receiver_id === val?.sender.id
            ? val?.current_wallet
            : val?.current_wallet
          ).toLocaleString(),
        });
      });
      setDataDetail(result);
    } catch (error) {
      throw error;
    } finally {
      setItem(arr);
    }
  };
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    console.log(
      "🚀 ~ file: DetailUsageStar.js:104 ~ DetailUsageStar ~ dataDetail:",
      item
    );
  }, [dataDetail]);
  useEffect(() => {
    getDetailUserUseStarHistory();
  }, [currentPage, limit, filter]);
  const onSubmit = (data) => {
    console.log(data);
    setFilter(filterUsageStar(data, id));
    setCurrentPage(1);
  };

  return (
    <>
      <Stack spacing={2}>
        <div>
          <div
            style={{
              background: "#F6F6F6 0% 0% no-repeat padding-box",
              border: "1px solid #E6ECF3",
              height: "50px",
              padding: "13px 22px",
            }}
          >
            <p>결제 정보</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formContainer">
              <div className="formContainer2">
                <FieldCustom label="입출">
                  <SelectCustomInput
                    setValue={setValue}
                    name="receiver_id"
                    data={state_revenue}
                    register={register("receiver_id")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="사유">
                  <SelectCustomInput
                    setValue={setValue}
                    name="type"
                    data={reason}
                    register={register("type")}
                  />
                </FieldCustom>
              </div>

              <div className="formContainer2">
                <FieldCustom label="기간검색">
                  <InputDateRangerCustome
                    registerStartDate={"dateStart"}
                    registerEndDate={"dateEnd"}
                    setValue={setValue}
                  />
                </FieldCustom>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  padding: "6px 16px",
                  width: "222px",
                  height: "56px",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                검색
              </Button>
            </div>
          </form>
          <DataTable
            columns={columns}
            total={total}
            items={item}
            page={page}
            currentPage={currentPage}
            changePage={handleChangePage}
            fileName="history"
            showLimit={false}
            setLimit={handleSetLimit}
            showLimitV2={true}
            limit={limit}
          />
        </div>
      </Stack>
    </>
  );
};

export default DetailUsageStar;
