import { Button, Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import { convertTextToKo, convertTimeZone } from "src/util/convert";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { headerFilter } from "src/util/data";
import FieldCustom from "./Fields/FieldCustom";
import InputDateRangerCustome from "./Fields/InputDateRangerCustome";
import { filterListCoin } from "src/util/setFilter";
import InputCustom from "./Fields/InputCustom";
const DetailListCoin = ({ id }) => {
  const { memeberInfo, reason } = headerFilter?.ListCointUserProfile;
  const API = GetApi;
  const { register, handleSubmit, setValue } = useForm();
  const [item, setItem] = useState([]);
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      flex: 1,
      sortable: false,
    },
    {
      field: "user_id",
      headerName: "회원 아이디",
      flex: 1,

      headerAlign: "center",
      sortable: false,
    },
    {
      field: "user_nickname",
      headerName: "회원 닉네임",
      flex: 1,

      headerAlign: "center",
      sortable: false,
    },
    {
      field: "reason",
      headerName: "사유",
      headerAlign: "center",
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell: (params) => {
        return <p>{convertTextToKo(params?.row?.reason)}</p>;
      },
    },
    {
      field: "coin",
      headerName: "적립금액",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
    {
      field: "coin_after",
      headerName: "적립 후 금액",
      flex: 1,

      headerAlign: "center",
      sortable: false,
      // renderCell: (params) => {
      // 	console.log("file: ListCoin.js:83 ~ params:", params);
      // 	return (
      // 		<>
      // 			{params.row.reason == "SUBSCRIPTION" ? (
      // 				<p>{data.wallet}</p>
      // 			) : (
      // 				<p>{params.row.coin + data.wallet}</p>
      // 			)}
      // 		</>
      // 	);
      // },
    },

    {
      field: "update_time",
      headerName: "변동날짜",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    receiver_id: id,
  });
  const [dataDetail, setDataDetail] = useState();
  const getDetailUseStarHistory = async () => {
    let arr = [];

    try {
      const result = await API.getUserUseStarHistory(
        JSON.stringify({
          type: { $notIn: ["ADMIN_SUB_STAR", "ADMIN_ADD_STAR"] },
          ...filter,
        }),
        currentPage,
        limit
      );
      setTotal(result?.results?.objects?.count);
      setPage(Math.max(Math.ceil(result.results.objects.count / limit), 1));
      result?.results?.objects?.rows.map((val, idx) => {
        return arr.push({
          id: val?.id,
          receiver_id: val?.receiver_id,
          no: idx + (currentPage - 1) * limit + 1,
          user_id: val?.sender?.identified_id,
          user_nickname: val?.sender?.nickname,
          reason: val?.type,
          coin:
            val?.type === "SUBSCRIPTION"
              ? "-"
              : (val?.total_fee * 200)?.toLocaleString(),
          coin_after: val?.current_wallet_receiver?.toLocaleString(),
          update_time: convertTimeZone(+val?.created_at_unix_timestamp),
        });
      });
      setDataDetail(result);
    } catch (error) {
      throw error;
    } finally {
      setItem(arr);
    }
  };
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    console.log(
      "🚀 ~ file: DetailListCoin.js:104 ~ DetailListCoin ~ dataDetail:",
      item
    );
  }, [dataDetail]);
  useEffect(() => {
    getDetailUseStarHistory();
  }, [currentPage, limit, filter]);
  const onSubmit = (data) => {
    console.log(data);
    setFilter(filterListCoin(data, id));
    setCurrentPage(1);
  };

  return (
    <>
      <Stack spacing={2}>
        <div>
          <div
            style={{
              background: "#F6F6F6 0% 0% no-repeat padding-box",
              border: "1px solid #E6ECF3",
              height: "50px",
              padding: "13px 22px",
            }}
          >
            <p>결제 정보</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formContainer">
              <div className="formContainer2">
                <FieldCustom label="정보">
                  <SelectCustomInput
                    setValue={setValue}
                    name="senderInfo"
                    width="150px"
                    data={memeberInfo}
                    register={register("senderInfo")}
                  />
                  <InputCustom
                    width="37%"
                    register={register("senderInput")}
                    placeholder="입력해주세요"
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="사유">
                  <SelectCustomInput
                    setValue={setValue}
                    name="type"
                    width="150px"
                    data={reason}
                    register={register("type")}
                  />
                </FieldCustom>
              </div>

              <div className="formContainer2">
                <FieldCustom label="기간검색">
                  <InputDateRangerCustome
                    registerStartDate={"dateStart"}
                    registerEndDate={"dateEnd"}
                    setValue={setValue}
                    style={{ width: "150px" }}
                  />
                </FieldCustom>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  padding: "6px 16px",
                  width: "222px",
                  height: "56px",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                검색
              </Button>
            </div>
          </form>
          <DataTable
            columns={columns}
            total={total}
            items={item}
            page={page}
            currentPage={currentPage}
            changePage={handleChangePage}
            fileName="history"
            showLimit={false}
            setLimit={handleSetLimit}
            showLimitV2={true}
            limit={limit}
          />
        </div>
      </Stack>
    </>
  );
};

export default DetailListCoin;
