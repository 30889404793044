import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import ExportCSV from "./ExportCSV";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Fade from "@mui/material/Fade";
import Pagination from "@mui/material/Pagination";
import CloseIcon from "@mui/icons-material/Close";
import { USersInConverSation } from "src/util/Utils";

import "moment/locale/ko";

import "./style.css";
import { convertTimeZone } from "src/util/convert";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 578,
  bgcolor: "background.paper",
  border: "1px solid #E6ECF3",
  boxShadow: 24,
};

const ModalTotalSender3 = ({ open, handleClose, data, type }) => {
  const listUser = type ? data : USersInConverSation(data);
  console.log("USersInConverSation", data);
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="chatMessHeader">
              <p>구매자수({listUser.length})</p>
              <CloseIcon className="closeBtn" onClick={handleClose} />
            </div>

            <div className="sender-container">
              <div className="sender-header">
                <p>총{listUser.length}건</p>
                <div className="sender-header-btn">
                  <ExportCSV csvData={data} fileName="user in conversation" />
                  <FormControl sx={{ height: "42px" }}>
                    <Select
                      className="select"
                      sx={{
                        "& legend": { display: "none" },
                        "& fieldset": { top: 0 },
                        marginLeft: "10px",
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={20}>20</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>

              <div className="sender-table">
                <div className="sender-table-header3">
                  <p>회원 아이디</p>
                  <p>회원 닉네임</p>
                  <p>구매날짜</p>
                  {/* <p>보유 스타</p> */}
                </div>
                {listUser.map((item, id) => {
                  const { sender } = item;

                  return (
                    <div
                      key={id}
                      className="sender-table-header3 sender-table-body"
                    >
                      <p>{sender && sender.identified_id}</p>
                      <p>{sender && sender.nickname}</p>
                      <p>{convertTimeZone(+item?.created_at_unix_timestamp)}</p>
                    </div>
                  );
                })}
              </div>
              <div className="sender-pagi">
                <Pagination
                  count={Math.max(Math.ceil(listUser.length / 10), 1)}
                  variant="outlined"
                  shape="rounded"
                />
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalTotalSender3;
