import React, { useEffect, useState, useContext } from "react";
import GetApi from "src/api/ListSetletmentApi";
import GetApiUser from "src/api/ListUserApi";

import DataTable from "src/components/DataTable";

import { convertTimeZone, convertVerify19 } from "src/util/convert";
import { LoadingContext } from "src/providers/LoadingProvider";
import { showError, showSuccess } from "src/util/error";

import MyTableColumns from "src/components/Columns/ColumnSetletment/Column";
import FilterSetletment from "src/components/FilterSetletment/FilterSetletment";
import { useForm } from "react-hook-form";
import { filterCelebWithDraw } from "src/util/setFilter";
import { Button } from "@mui/material";

const API = GetApi;
const APIUSER = GetApiUser;
const WITHDRAW = {
  LIMIT_CONDITION: 1000,
  // LIMIT_CONDITION_WITH_AGENCY: 20000,
  // LIMIT_CONDITION_WITHOUT_AGENCY: 50000,
  // STATE: {
  //   PROCESS: 'PROCESS',
  //   ACCEPT: 'ACCEPT',
  //   DENY: 'DENY',
  //   AGENCY_PENDING: 'AGENCY_PENDING',
  //   PENDING: 'PENDING',
  // },
};

const ListSetletment = () => {
  const token = localStorage.getItem("token");
  const { register, handleSubmit, setValue } = useForm();
  const agency = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const { id } = agency || "";
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [arrId, setArrId] = useState([]);
  const [disable, setDisable] = useState(false);
  const [filter, setFilter] = useState({
    "$user.agency_id$": id,
  });
  const arr = [];
  const { showLoading, hideLoading } = useContext(LoadingContext);

  // const handleAccept = async (arr) => {
  //   const response = await API.accept({
  //     withdraw_ids: arr,
  //   });
  //   showSuccess("Success");
  //   fetchAll();
  // };
  const handleAccept = async (arr) => {
    setDisable(true);
    try {
      const response = await API.acceptCeleb({
        user_ids: arr,
      });
      console.log(response);
      showSuccess("Success");
      fetchAll(1);
    } catch (error) {
      showError(error.response.data.message || "Had Unknown Error");
    }
    setDisable(false);
  };
  const handleDeny = async (arr) => {
    const response = await API.deny({
      withdraw_ids: arr,
    });
    showSuccess("Denied");
    fetchAll();
  };
  const columns = MyTableColumns(handleAccept, handleDeny);

  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const fetchAll = async (lenght) => {
    showLoading();
    try {
      let response;
      if (lenght === 1) {
        response = await APIUSER.getAll2(
          currentPage,
          limit,
          JSON.stringify({
            user_type: "CELEBRITY",
            register_status: "ACCEPT",
            agency_id: agency?.id || null,
            is_available_withdraw: true,
          })
        );
        setTotal(response?.results?.objects.count);
        if (response.code === 200) {
          // xu li pagination
          let pages = Math.max(
            Math.ceil(response.results.objects.count / limit),
            1
          );
          setPage(pages);

          response.results.objects.rows.map((val, idx) => {
            arr.push({
              id: val.id,
              no: val?.agency_member_no,
              nickname: val?.nickname || "-",
              sex: val?.sex || "",
              identified_id: val?.identified_id || "-",
              age_verified: convertVerify19(val?.is_over_19),
              donated_money:
                val?.donated_money || 0 + val?.subscribe_revenue || 0,
              refund_coin:
                (val?.donated_money + val?.subscribe_revenue) % WITHDRAW.LIMIT_CONDITION || 0,
              total_revenue:
                Math.floor(
                  (val?.donated_money + val?.subscribe_revenue) / WITHDRAW.LIMIT_CONDITION
                ) * WITHDRAW.LIMIT_CONDITION || 0,
              accept: true,
              status_account: val?.status_account || "-",
              intermediary_fee: val?.intermediary_fee || 0,
              created_at: "-",
              processing_date: "-",
              state: "-",
              birthday: Number(val?.birthday || 0),
            });
          });
        }
      } else {
        response = await API.getAllById(
          currentPage,
          limit,
          JSON.stringify({
            ...filter,
          })
        );
        setTotal(response?.results?.objects.count);
        if (response.code === 200) {
          // xu li pagination
          let pages = Math.max(
            Math.ceil(response.results.objects.count / limit),
            1
          );
          setPage(pages);
          response.results.objects.rows.map((val, idx) => {
            arr.push({
              id: val?.id,
              no: val?.user?.agency_member_no,
              nickname: val?.user?.nickname || "-",
              age: convertTimeZone(
                parseInt(val?.user?.age) || val?.user?.created_at_unix_timestamp
              ),
              sex: val?.user?.sex || "",
              wallet: val?.user?.wallet || 0,

              register_status: val?.user?.register_status ?? "",
              created_at: convertTimeZone(
                parseInt(val?.created_at_unix_timestamp) ||
                  val?.created_at_unix_timestamp
              ),
              processing_date: val?.processed_at_unix_timestamp
                ? convertTimeZone(parseInt(val?.processed_at_unix_timestamp))
                : "-",
              refund_coin: val?.refund_coin,
              state: val?.state,
              total_revenue: val?.total_revenue || 0,
              donated_money: val?.refund_coin + val?.total_revenue || 0,
              intermediary_fee: val?.user?.intermediary_fee || 0,
              avatar: val?.user?.avatar,
              phone: val?.user?.phone ? `${val?.user?.phone}` : "-",
              image_card: val?.user?.image_card,
              realname: val?.user?.name || "-",
              agency_code: val?.user?.agency_code || "-",
              identified_id: val?.user?.identified_id || "-",
              age_verified: val?.user?.verified || "-",
              status_account: val?.user?.status_account || "-",
              hasgtag: val?.user?.hasgtag || "-",
              user_type: val?.user?.user_type,
              subscribeCount: val?.user?.subscribers_count || 0,
              follower_count: val?.user?.follower_count || 0,
              email: val?.user?.email || "",
              accept: false,
              birthday: Number(val?.user?.birthday || 0),
            });
          });
        }
      }
    } catch (error) {
      hideLoading();
      showError(error);
    } finally {
      hideLoading();
      setItems(arr);
    }
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  const onSubmit = (data) => {
    console.log(data);
    const filter = filterCelebWithDraw(data, id);
    setFilter(filter);
    setCurrentPage(1);
  };
  useEffect(() => {
    console.log(
      "🚀 ~ file: Setletment.js:193 ~ ListSetletment ~ filter:",
      filter
    );
    fetchAll(Object.keys(filter).length);
  }, [currentPage, limit, filter]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 20 }}>
        <FilterSetletment register={register} setValue={setValue} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "black",
              padding: "6px 16px",
              width: "222px",
              height: "56px",
              borderRadius: "10px",
            }}
            type="submit"
          >
            검색
          </Button>
        </div>
      </form>

      <DataTable
        columns={columns}
        total={total}
        items={items}
        page={page}
        currentPage={currentPage}
        changePage={handleChangePage}
        limit={limit}
        fileName="SETLETMENT"
        checkboxSelection={true}
        onSelectionModelChange={(e) => setArrId(e)}
        selectionModel={arrId}
        showLimit={false}
        showLimitV2={true}
        setLimit={handleSetLimit}
        handleDeny={handleDeny}
        handleAccept={handleAccept}
        hiddenDeny
        disable={disable}
      />
    </div>
  );
};

export default ListSetletment;
