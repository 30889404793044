import React from "react";
import FieldCustom from "../Fields/FieldCustom";
import SelectCustomInput from "../Fields/SelectCustomInput";
import InputCustom from "../Fields/InputCustom";
import { headerFilter } from "src/util/data";
import InputDateRangerCustome from "../Fields/InputDateRangerCustome";

function FilterSetletment({ register, setValue }) {
  const { memberInfoSelect, statusAccount, seacrhTime, accept, genderArr } =
    headerFilter.setletment;
  return (
    <div>
      <div className="formContainer" style={{ background: "white" }}>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="회원정보">
            <SelectCustomInput
              setValue={setValue}
              width="50%"
              data={memberInfoSelect}
              register={register("senderInfo")}
              name="senderInfo"
            />
            <InputCustom
              width="100%"
              register={register("senderInput")}
              placeholder="입력해주세요"
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="성별">
            <SelectCustomInput
              setValue={setValue}
              data={genderArr}
              register={register("sex")}
              name="sex"
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="상태">
            <SelectCustomInput
              setValue={setValue}
              data={statusAccount}
              register={register("status_account")}
              name="status_account"
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="정산상태">
            <SelectCustomInput
              setValue={setValue}
              name="state"
              data={accept}
              register={register("state")}
            />
          </FieldCustom>
        </div>

        <div className="formContainer2">
          <FieldCustom minWidth={120} label="기간 설정">
            <SelectCustomInput
              setValue={setValue}
              name="dateInput"
              width="20%"
              data={seacrhTime}
              register={register("dateInput")}
            />
            <InputDateRangerCustome
              registerStartDate={"dateStart"}
              registerEndDate={"dateEnd"}
              setValue={setValue}
            />
          </FieldCustom>
        </div>
      </div>
    </div>
  );
}

export default FilterSetletment;
