import { Button, Stack, Tooltip } from "@mui/material";
import DataTable from "./DataTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import { convertTextToKo, convertTimeZone } from "src/util/convert";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { headerFilter } from "src/util/data";
import FieldCustom from "./Fields/FieldCustom";
import InputCustom from "./Fields/InputCustom";
import InputDateRangerCustome from "./Fields/InputDateRangerCustome";
import { filterPayment } from "src/util/setFilter";
const DetailPayment = ({ id }) => {
  const { methodPayment, statusPayment } = headerFilter?.PaymentUserProfile;
  const API = GetApi;
  const { register, handleSubmit, setValue } = useForm();
  const [item, setItem] = useState([]);
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      minWidth: 200,
    },
    {
      field: "purchase_date",
      headerName: "구매날짜",
      flex: 1,
      minWidth: 200,

      headerAlign: "center",
      sortable: false,
    },
    {
      field: "state",
      headerName: "상태",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <p>{convertTextToKo(params?.row?.state)}</p>;
      },
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "product_name",
      headerName: "상품명",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <p>{Number(params?.row?.product_name).toLocaleString()}</p>;
      },
      sortable: false,
    },
    {
      field: "payer_nickname",
      headerName: "입금자 정보",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,

      sortable: false,
    },
    {
      field: "order_number",
      headerName: "주문번호",
      flex: 1,
      minWidth: 200,

      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.order_number}>
            <p className="table-cell-trucate">{params.row.order_number}</p>
          </Tooltip>
        );
      },
    },
    {
      field: "method",
      headerName: "결제방법",
      flex: 1,
      minWidth: 200,

      headerAlign: "center",
      sortable: false,
    },
    {
      field: "amount",
      headerName: "결제금액",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <p>{Number(params?.row?.amount).toLocaleString()}</p>;
      },
      sortable: false,
    },
    {
      field: "star",
      headerName: "지급 스타",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        return <p>{Number(params?.row?.star).toLocaleString()}</p>;
      },
      headerAlign: "center",
      sortable: false,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    user_id: id,
  });
  const [dataDetail, setDataDetail] = useState();
  const getDetailUserSaleHistory = async () => {
    let arr = [];

    try {
      const result = await API.getUserSaleHistory(
        JSON.stringify({ ...filter }),
        currentPage,
        limit
      );
      setTotal(result?.results?.objects?.count);
      setPage(Math.max(Math.ceil(result.results.objects.count / limit), 1));
      result?.results?.objects?.rows.map((val, idx) => {
        return arr.push({
          id: val?.id,
          no: idx + (currentPage - 1) * limit + 1,
          purchase_date: convertTimeZone(+val?.created_at_unix_timestamp),
          state: val?.state || "-",
          product_name: val?.pack?.title || "-",
          payer_nickname: val?.user?.nickname || "-",
          order_number: val?.transaction_number,
          method: val?.type || "-",
          amount: val?.pack.price,
          star: val?.pack.amount,
        });
      });
      setDataDetail(result);
    } catch (error) {
      throw error;
    } finally {
      setItem(arr);
    }
  };
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    console.log(
      "🚀 ~ file: DetailPayment.js:104 ~ DetailPayment ~ dataDetail:",
      item
    );
  }, [dataDetail]);
  useEffect(() => {
    getDetailUserSaleHistory();
  }, [currentPage, limit, filter]);
  const onSubmit = (data) => {
    console.log(data);
    setFilter(filterPayment(data, id));
    setCurrentPage(1);
  };

  return (
    <>
      <Stack spacing={2}>
        <div>
          <div
            style={{
              background: "#F6F6F6 0% 0% no-repeat padding-box",
              border: "1px solid #E6ECF3",
              height: "50px",
              padding: "13px 22px",
            }}
          >
            <p>결제 정보</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formContainer">
              <div className="formContainer2">
                <FieldCustom label="상태">
                  <SelectCustomInput
                    setValue={setValue}
                    name="state"
                    data={statusPayment}
                    register={register("state")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="결제방법">
                  <SelectCustomInput
                    setValue={setValue}
                    name="type"
                    data={methodPayment}
                    register={register("type")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="주문번호">
                  <InputCustom
                    register={register("transaction_number")}
                    placeholder="입력해주세요"
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="입금자 정보">
                  <InputCustom
                    register={register(`nickname`)}
                    placeholder="입력해주세요"
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="기간검색">
                  <InputDateRangerCustome
                    registerStartDate={"dateStart"}
                    registerEndDate={"dateEnd"}
                    setValue={setValue}
                  />
                </FieldCustom>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  padding: "6px 16px",
                  width: "222px",
                  height: "56px",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                검색
              </Button>
            </div>
          </form>
          <DataTable
            columns={columns}
            total={total}
            items={item}
            page={page}
            currentPage={currentPage}
            changePage={handleChangePage}
            fileName="history"
            showLimit={false}
            setLimit={handleSetLimit}
            showLimitV2={true}
            limit={limit}
          />
        </div>
      </Stack>
    </>
  );
};

export default DetailPayment;
