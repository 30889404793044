import { Button, Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import { convertTimeZone, convertTextToKo } from "src/util/convert";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { headerFilter } from "src/util/data";
import FieldCustom from "./Fields/FieldCustom";
import InputDateRangerCustome from "./Fields/InputDateRangerCustome";
import { filterBuyFeed } from "src/util/setFilter";
import InputCustom from "./Fields/InputCustom";
import { checkPeriodType, handleType } from "src/util/Utils";
import { CardModal } from "./CardImg";
import ModalTotalSender3 from "./ModalTotalSender3";
const DetailBuyFeed = ({ id }) => {
  const { memeberInfo, typePost, pruchasePeriod, setting, seacrhTime } =
    headerFilter.ListFeedUserProfile;
  const API = GetApi;
  const { register, handleSubmit, setValue } = useForm();
  const [item, setItem] = useState([]);
  const [openModal, setOpenmodal] = useState(false);
  const [itemMedias, setItemMedias] = useState([]);
  const [itemsUsers, setItemsUsers] = useState([]);
  const [openModalTotalSender, setopenModalTotalSender] = useState(false);

  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",

      sortable: false,
    },
    {
      field: "type",
      headerName: "종류",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <p
            style={{
              textDecoration: `${
                params.row.medias.length > 0 ? "underline" : ""
              }`,
              cursor: "pointer",
            }}
            onClick={() => {
              setItemMedias(params.row.medias);
              setOpenmodal(true);
            }}
          >
            {params.row.type}
          </p>
        );
      },
    },
    {
      field: "time_pay",
      headerName: "구매 기간",

      headerAlign: "center",
      sortable: false,
    },

    {
      field: "setting",
      headerName: "설정",
      headerAlign: "center",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return <div>{params?.row?.setting === "FREE" ? "무료" : "유료"}</div>;
      },
    },
    {
      field: "star",
      headerName: "스타",

      headerAlign: "center",
      sortable: false,
    },

    {
      field: "create_at",
      headerName: "등록날짜",
      headerAlign: "center",
      minWidth: 200,
      flex: 1,
      sortable: false,
    },
    {
      field: "total_user",
      headerName: "구매자 수",
      headerAlign: "center",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <p
            style={{
              textDecoration: `${params.row.total_user > 0 ? "underline" : ""}`,
              cursor: "pointer",
            }}
            onClick={() =>
              params.row.total_user > 0 && handleShowCeleb(params.row.list_user)
            }
          >
            {params.row.total_user}
          </p>
        );
      },
    },

    {
      field: "time_purchase",
      headerName: "구매날짜",
      headerAlign: "center",
      flex: 1,
      minWidth: 200,
      sortable: false,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    user_id: id,
    approve_state: "APPROVED",
  });
  const [filter2, setFilter2] = useState(null);
  const [filter3, setFilter3] = useState(null);

  const [dataDetail, setDataDetail] = useState();
  const getDetailUsageStarPost = async () => {
    let arr = [];

    try {
      const result = await API.getUserUsageStarPost(
        filter,
        filter2,
        filter3,
        currentPage,
        limit
      );
      setTotal(result?.results?.objects?.count);
      setPage(Math.max(Math.ceil(result.results.objects.count / limit), 1));
      result?.results?.objects?.rows.map((val, idx) => {
        return arr.push({
          id: val?.id,
          no: idx + (currentPage - 1) * limit + 1,
          setting: val?.secret_album_id ? val?.secret_album?.type : val?.type,
          star: val?.price || "-",
          create_at: convertTimeZone(+val?.created_at_unix_timestamp),
          time_purchase: convertTimeZone(+val?.last_date_purchase),
          total_user: val?.usage_star_post?.length || "-",
          type:
            handleType(val?.type_media) +
            `${val?.type_media ? `(${val?.medias?.length}) ` : ""}`,
          time_pay: val?.secret_album_id
            ? checkPeriodType(val?.secret_album?.period_type)
            : "-",
          list_user: val?.usage_star_post || [],
          medias: val?.medias || [],
        });
      });
      setDataDetail(result);
    } catch (error) {
      throw error;
    } finally {
      setItem(arr);
    }
  };
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    getDetailUsageStarPost();
    console.log("get detial usage post ne");
  }, [currentPage, limit, filter]);

  const handleShowCeleb = (celeb) => {
    setItemsUsers(() => celeb);
    setopenModalTotalSender(true);
  };
  const onSubmit = (data) => {
    const filter = filterBuyFeed(data, id);
    console.log("🚀 ~ file: DetailBuyFeed.js:202 ~ onSubmit ~ filter:", filter);
    setFilter(filter);
    // setFilter2(filter[1]);
    // setFilter3(filter[2]);
    setCurrentPage(1);
  };

  return (
    <>
      <Stack spacing={2}>
        <div>
          <div
            style={{
              background: "#F6F6F6 0% 0% no-repeat padding-box",
              border: "1px solid #E6ECF3",
              height: "50px",
              padding: "13px 22px",
            }}
          >
            <p>결제 정보</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formContainer">
              <div className="formContainer2">
                <FieldCustom label="종류">
                  <SelectCustomInput
                    setValue={setValue}
                    name="type_media"
                    data={typePost}
                    register={register("type_media")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="구매기간">
                  <SelectCustomInput
                    setValue={setValue}
                    name="period_type"
                    data={pruchasePeriod}
                    register={register("period_type")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="설정">
                  <SelectCustomInput
                    setValue={setValue}
                    name="type"
                    data={setting}
                    register={register("type")}
                  />
                </FieldCustom>
              </div>
              {/* <div className="formContainer2">
                <FieldCustom label="회원정보">
                  <SelectCustomInput
                    setValue={setValue}
                    name="senderInfo"
                    width="20%"
                    data={memeberInfo}
                    register={register("senderInfo")}
                  />
                  <InputCustom
                    width="100%"
                    register={register("senderInput")}
                    placeholder="입력해주세요"
                  />
                </FieldCustom>
              </div> */}
              <div className="formContainer2">
                <FieldCustom label="기간검색">
                  <SelectCustomInput
                    setValue={setValue}
                    name="dateInput"
                    width="20%"
                    data={seacrhTime}
                    register={register("dateInput")}
                  />
                  <InputDateRangerCustome
                    registerStartDate={"dateStart"}
                    registerEndDate={"dateEnd"}
                    setValue={setValue}
                  />
                </FieldCustom>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  padding: "6px 16px",
                  width: "222px",
                  height: "56px",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                검색
              </Button>
            </div>
          </form>
          <DataTable
            columns={columns}
            total={total}
            items={item}
            page={page}
            currentPage={currentPage}
            changePage={handleChangePage}
            fileName="history"
            showLimit={false}
            setLimit={handleSetLimit}
            showLimitV2={true}
            limit={limit}
          />
          <ModalTotalSender3
            open={openModalTotalSender}
            handleClose={() => setopenModalTotalSender(false)}
            data={itemsUsers}
            type="Agency"
          />
          <CardModal
            open={openModal}
            handleClose={() => setOpenmodal(false)}
            item={{ medias: itemMedias }}
            typeCard={"POST"}
          />
        </div>
      </Stack>
    </>
  );
};

export default DetailBuyFeed;
