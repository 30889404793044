import React from "react";
import DateRangePicker from "src/components/DateRangePicker";

const DataRangePickerField = ({
  field,
  form,
  DateRange,
  setDateRange,
  placeholder,
}) => {
  return (
    <>
      <DateRangePicker
        DateRange={DateRange}
        setDateRange={setDateRange}
        name={field.name}
        form={form}
        placeholder={placeholder}
      />
    </>
  );
};

export default DataRangePickerField;
