export const data = {
  banner: {
    fields: [
      { key: "#" },
      { key: "image" },
      { key: "url" },

      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
      },
    ],
  },

  employee: {
    optionFilter: [
      { value: "username", label: "User name" },
      { value: "fullname", label: "Full name" },
      { value: "email", label: "Email" },
      { value: "phone", label: "Phone" },
    ],
    fields: [
      { key: "#" },
      { key: "username" },
      { key: "fullname" },
      { key: "avatar" },
      { key: "email" },
      { key: "phone" },
      // { key: "type", _style: { minWidth: 120 } },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
      },
    ],
  },

  member: {
    fields: [
      { key: "#" },
      // { key: "username" },
      { key: "nickname" },
      { key: "identified_id" },
      { key: "avatar" },
      { key: "avatar_request", label: "List avatar request" },
      { key: "description" },
      { key: "birthday", _style: { minWidth: "100px" } },
      // { key: "job"},
      { key: "gender" },
      // { key: "image_card", label: "Card", },
      // { key: "register_status", label: "Status Register", _style: { minWidth: "170px" } },
      // text === "INAPP" ? { key: "email" } : { key: "phone" },
      { key: "email" },
      { key: "phone" },
      { key: "posts" },
      // { key: "following" },
      // { key: "follower" },
      // { key: "wallet", label: "Wallet (Dool)" },
      // { key: "average_price_audio", label: "Average Price Audio ($)" },
      // { key: "average_price_video", label: "Average Price Video ($)" },
      // { key: "pay_per_call", label: "Pay Per Call (Dool)", _style: { minWidth: "120px" } },
      // { key: "pay_per_video_call", label: "Pay Per Video Call (Dool)", _style: { minWidth: "150px" } },
      // {
      // 	key: "total_income_audio_call",
      // 	label: "Total Time Received Audio Call",
      // 	_style: { minWidth: "150px" },
      // },
      // {
      // 	key: "total_income_video_call",
      // 	label: "Total Time Received Video Call",
      // 	_style: { minWidth: "150px" },
      // },
      // {
      // 	key: "total_earned_audio_call",
      // 	label: "Total Earned Audio Call (Dool)",
      // 	_style: { minWidth: "150px" },
      // },
      // {
      // 	key: "total_earned_video_call",
      // 	label: "Total Earned Video Call (Dool)",
      // 	_style: { minWidth: "150px" },
      // },
      { key: "login_type" },
      { key: "user_type", label: "User Type" },
      { key: "created_at", label: "Created At", _style: { minWidth: "150px" } },
      { key: "updated_at", label: "Updated At", _style: { minWidth: "150px" } },

      // { key: "status" },
      { key: "is_presentative", label: "Presentative" },
      { key: "is_black", label: "Black celeb" },
      {
        key: "show_details",
        label: "Action",
        _style: { minWidth: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],
    fieldsManageCeleb: [
      { key: "No" },
      { key: "nickname" },
      { key: "email" },
      { key: "birthday", _style: { minWidth: "100px" } },
      { key: "gender" },
      { key: "phone" },
      { key: "platorm", label: "Platform" },
      { key: "platorm_link", label: "Platform Link" },
      // { key: "agency_code", label: "Agency Code" },
      {
        key: "date_register",
        label: "Day Register",
        _style: { minWidth: "100px" },
      },
      { key: "processing_date", label: "Processing Date" },
      { key: "register_status", label: "Status Register" },
      { key: "processing", label: "Processing" },
    ],
    optionFilter: [
      // { value: "username", label: "Username" },
      { value: "nickname", label: "Nick name" },
      // { value: "description", label: "Description" },
      { value: "sex", label: "Gender" },
      { value: "phone", label: "Phone" },
      { value: "login_type", label: "Login type" },
      { value: "user_type", label: "User type" },
      // { value: "state", label: "Status" },
    ],
  },
  report: {
    fields: [
      { key: "No", sorter: false, _style: { textAlign: "center" } },
      {
        key: "nickname",
        label: "User",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "gender",
        label: "Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "phone",
        label: "Phone",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "type",
        label: "Type",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "reasons",
        label: "Reason",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "content",
        label: "Content",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_reported",
        label: "User Reported",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_gender",
        label: "User Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "created_at",
        label: "Created At",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "status",
        label: "Status",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "action",
        label: "Action",
        sorter: false,
        _style: { textAlign: "center" },
      },
    ],
    optionFilter: [
      { value: "$user.nickname$", label: "Nick name" },
      { value: "type", label: "Type Report" },
    ],
  },

  post: {
    fields: [
      { key: "No", sorter: false },
      { key: "user", label: "Nickname", sorter: false },
      { key: "sex", label: "Gender", sorter: false },
      { key: "type_media", label: "Type Media", sorter: false },
      { key: "type_post", label: "Type Post", sorter: false },
      { key: "subscribe_type", label: "Subcrise", sorter: false },
      { key: "purchase_start", label: "Purchase start", sorter: false },
      { key: "expose_time", label: "Expose Time", sorter: false },
      { key: "content", sorter: false },
      { key: "file", label: "File", sorter: false },
      // { key: "image", sorter: false },
      // { key: "hashtag", sorter: false },
      // { key: "like_post", label: "Likes" },
      // { key: "amount_comment", label: "Comments" },
      { key: "created_at", label: "Created At", _style: { minWidth: "100px" } },
      // { key: "updated_at", label: "Updated At", _style: { minWidth: "100px" } },
      // { key: "status", sorter: false },
      {
        key: "show_details",
        label: "Other",
        _style: { textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],
    optionFilterNoU: [
      { value: "nickname", label: "Nickname" },
      // { value: "content", label: "Content" },
      // { value: "hashtag", label: "Hashtag" },
    ],
    optionFilterWithU: [
      // { value: "nickname", label: "Nickname" },
      { value: "content", label: "Content" },
      { value: "hashtag", label: "Hashtag" },
    ],
    optionStatus: [
      { value: "ALL", label: "All" },
      { value: "PUBLISH", label: "Publish" },
      { value: "HIDDEN", label: "Hidden" },
    ],
  },

  faq: {
    fields: [
      { key: "#", sorter: false },
      { key: "title", label: "Title", sorter: false },
      { key: "content", label: "Content", sorter: false },
      { key: "type", label: "Type", sorter: false },
      { key: "created_at" },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionFilter: [
      { value: "title", label: "Title" },
      { value: "content", label: "Content" },
    ],

    optionStatus: [
      { value: "", label: "All" },
      { value: "FAQ", label: "FAQ" },
      { value: "NOTICE", label: "NOTICE" },
    ],
  },

  policy: {
    fields: [
      { key: "#", sorter: false },
      // { key: "content", label: "Content", sorter: false },
      { key: "type", label: "Type", sorter: false },
      { key: "created_at" },
      { key: "updated_at" },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionStatus: [
      { value: "", label: "All" },
      { value: "TERMS_OF_SERVICE", label: "TERMS OF SERVICE" },
      { value: "PRIVACY_STATEMENT", label: "PRIVACY STATEMENT" },
      { value: "VIDEO_CALL_TUTORIAL", label: "VIDEO CALL TUTORIAL" },
      { value: "MESSAGE_TUTORIAL", label: "MESSAGE TUTORIAL" },
      { value: "TERMS_AND_CONDITIONS", label: "TERMS AND CONDITIONS" },
      { value: "AGE", label: "AGE" },
      { value: "RECEIVCE_ADS", label: "RECEIVCE ADS" },
      { value: "DELETE_ACCOUNT", label: "DELETE ACCOUNT" },
    ],
  },

  inquiry: {
    fields: [
      { key: "#" },
      { key: "nickname" },
      // { key: "admin_nickname" },
      // { key: "content", _style: { minWidth: "300px" } },
      { key: "type" },
      // { key: "images" },
      { key: "state", label: "Status" },
      { key: "created_at", _style: { minWidth: "220px" }, label: "Created At" },
      // { key: "date_edit", label: "Date Edit" },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionFilter: [
      { value: "nickname", label: "Nickname" },
      // { value: "admin_nickname", label: "Admin Nickname" },
      // { value: "title", label: "Title" },
      // { value: "state", label: "Status" },
    ],
    optionStatus: [
      { value: "", label: "All" },
      { value: "REQUEST", label: "REQUEST" },
      { value: "DONE", label: "DONE" },
    ],
  },

  defaultMess: {
    fields: [
      { key: "#", sorter: false },
      // {key : 'title',label : 'Title',sorter : false},
      { key: "content", label: "Content", sorter: false },
      // { key: "type_account", label: "Type", sorter: false },
      // {key : "subscribe_type", label : "Subcrise Type"},
      { key: "image", sorter: false },
      { key: "created_at" },
      { key: "updated_at" },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionFilter: [
      { value: "title", label: "Title" },
      { value: "content", label: "Content" },
    ],

    optionStatus: [
      { value: "", label: "All" },
      { value: "FAQ", label: "FAQ" },
      { value: "NOTICE", label: "NOTICE" },
    ],
  },

  exchange: {
    fields: [
      { key: "#", sorter: false },
      // { key: "user", label: "Nickname", sorter: false },
      // { key: "amount" },
      // { key: "amount_dool" },
      // { key: "state", label: "Status", sorter: false },
      // { key: "note", label: "Note of Admin", sorter: false },
      { key: "exchange_rate" },
      // { key: "created_at" },
      { key: "updated_at" },

      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionFilter: [
      { value: "nickname", label: "Nickname" },
      { value: "amount", label: "Amount" },
      { value: "amount_dool", label: "Amount Dool" },
      { value: "note", label: "Note" },
      // { value: "state", label: "Status" },
    ],

    optionStatus: [
      { value: "ALL", label: "All" },
      { value: "PROCESSING", label: "Processing" },
      { value: "REJECTED", label: "Rejected" },
      { value: "COMPLETED", label: "Completed" },
      { value: "CANCELED", label: "Canceled" },
    ],
  },
  conversation: {
    fields: [
      { key: "No", sorter: false, _style: { textAlign: "center" } },
      { key: "status", label: "Status", sorter: false },
      { key: "checked_Celeb", sorter: false, _style: { textAlign: "center" } },
      {
        key: "image_celeb",
        label: "Celeb Image",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "celeb_nickname",
        label: "Celeb Nick Name",
        sorter: false,
        _style: { textAlign: "center", width: "200px" },
      },
      {
        key: "type",
        label: "Type",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "type_detail",
        label: "Type Detail",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "last_message",
        label: "Last Message",
        sorter: false,
        _style: { textAlign: "center", width: "150px" },
      },
      {
        key: "file",
        label: "File",
        sorter: false,
        _style: { textAlign: "center" },
      },
      { key: "checked_normal", sorter: false, _style: { textAlign: "center" } },
      {
        key: "image_normal",
        label: "Normal Image",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "normal_nickname",
        label: "Normal Nick Name",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "amount_user_send",
        label: "Amount User Send",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "message_updated_at_unix_timestamp",
        label: "Last Message Time",
        _style: { textAlign: "center" },
      },
      // {
      //   key: "show_details",
      //   label: "Action",
      //   _style: { minWidth: "380px", textAlign: "center" },
      //   // sorter: false,
      //   // filter: false,
      // },
    ],
  },

  notification: {
    fields: [
      { key: "#" },
      { key: "title", _style: { minWidth: "200px" } },
      { key: "message_title", _style: { minWidth: "200px" } },
      { key: "message_content", _style: { minWidth: "200px" } },
      { key: "frequency" },
      {
        key: "sending_unix_timestamp",
        label: "Date of processing",
        _style: { minWidth: "200px" },
      },
      {
        key: "show_details",
        label: "Action",
        _style: { width: "200px", textAlign: "center" },
        // sorter: false,
        // filter: false,
      },
    ],

    optionFilter: [
      { value: "title", label: "Title" },
      { value: "message_title", label: "Message title" },
      { value: "message_content", label: "Message content" },
    ],
  },
  follow: {
    fields: [
      { key: "No" },
      {
        key: "celeb_nickname",
        label: "Celeb",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "celeb_gender",
        label: "Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "celeb_phone",
        label: "Phone",
        sorter: false,
        _style: { textAlign: "center" },
      },
      // {
      // 	key: "agency_code",
      // 	label: "Agency Code",
      // 	sorter: false,
      // 	_style: { textAlign: "center" },
      // },
      {
        key: "created_at",
        label: "Created At",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "last_access",
        label: "Last Access",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_nickname",
        label: "User",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_gender",
        label: "Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_phone",
        label: "phone",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "start_date",
        label: "Start Date",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "end_date",
        label: "End Date",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "status",
        label: "Status",
        sorter: false,
        _style: { textAlign: "center" },
      },
    ],
  },
  subcription: {
    fields: [
      { key: "No" },
      {
        key: "celeb_nickname",
        label: "Celeb",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "celeb_gender",
        label: "Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "celeb_phone",
        label: "Phone",
        sorter: false,
        _style: { textAlign: "center" },
      },
      // {
      // 	key: "agency_code",
      // 	label: "Agency Code",
      // 	sorter: false,
      // 	_style: { textAlign: "center" },
      // },
      {
        key: "type_subcribe",
        label: "Type Subcrise",
        sorter: false,
        _style: { textAlign: "center" },
      },

      {
        key: "user_nickname",
        label: "User",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "user_gender",
        label: "Gender",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "created_at",
        label: "Subcribed date",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "deleted_at",
        label: "Unsubcribed date",
        sorter: false,
        _style: { textAlign: "center" },
      },
      {
        key: "status",
        label: "Status",
        sorter: false,
        _style: { textAlign: "center" },
      },
    ],
  },

  purchaseHistory: {
    fields: [
      { key: "No" },
      {
        key: "created_at_unix_timestamp",
        label: "Purchase Date",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "status_purchase",
        label: "Purchase Status",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "platform_paid",
        label: "Platform Paid",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "product_name",
        label: "product name",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "membership_number",
        label: "Memebership number",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "sender_nickname",
        label: "Memeber(ID)",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "order_number",
        label: "Order Number",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "payment_method",
        label: "Payment Method",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "amount_of_payment",
        label: "Amount Of Payment",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "paid_star",
        label: "Paid Star",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "process",
        label: "Process",
        _style: { textAlign: "center" },
        sorter: false,
      },
    ],
  },
  monthlysale: {
    fieldsMonthInYear: [
      { key: "No" },
      {
        key: "date",
        label: "date",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "total_month",
        label: "Total Month",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "commission",
        label: "Commission",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "profit_or_loss",
        label: "Profit or Loss",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "number_of_subscribers",
        label: "Number of Subscribers",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "payment_rate",
        label: "Payment Rate",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "hand_phone",
        label: "Hand Phone",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "card",
        label: "Card",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "google",
        label: "Google",
        _style: { textAlign: "center" },
        sorter: false,
      },

      {
        key: "apple",
        label: "Apple",
        _style: { textAlign: "center" },
        sorter: false,
      },
    ],
  },
  dailysale: {
    fields: [
      { key: "No" },
      {
        key: "date",
        label: "date",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "total_date",
        label: "Total Date",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "commission",
        label: "Commission",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "profit_or_loss",
        label: "Profit or Loss",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "number_of_subscribers",
        label: "Number of Subscribers",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "payment_rate",
        label: "Payment Rate",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "hand_phone",
        label: "Hand Phone",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "card",
        label: "Card",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "google",
        label: "Google",
        _style: { textAlign: "center" },
        sorter: false,
      },

      {
        key: "apple",
        label: "Apple",
        _style: { textAlign: "center" },
        sorter: false,
      },
    ],
  },
  accumulatedStarCeleb: {
    fields: [
      { key: "No" },
      {
        key: "celeb_nickname",
        label: "Celeb(ID)",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "app",
        label: "APP",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "celeb_gender",
        label: "Gender",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "celeb_phone",
        label: "Phone",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "total_fee",
        label: "Total",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "coin_after_earning",
        label: "Coin After earning",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "type",
        label: "Type",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "user_nickname",
        label: "User(ID)",
        _style: { textAlign: "center" },
        sorter: false,
      },
      {
        key: "created_at_unix_timestamp",
        label: "Created At",
        _style: { textAlign: "center" },
        sorter: false,
      },
    ],
  },
};

export const typeMedia = {
  Image: "사진",
  video: "동영상",
  album: "앨범(2)",
};

export const typePost = {
  publicAll: "FREE",
  ONE_TIME: "ONE_TIME",
  SUBCCRIBE: "SUBSCRIBE",
};
export const typePay = {
  PROCESS: "PROCESS",
  PENDING: "PENDING",
  ACCEPT: "ACCEPT",
  DENY: "DENY",
  AGENCY_PENDING: "AGENCY_PENDING",
};

export const platFormname = {
  YOUTUBE: "YOUTUBE",
  INSTAGRAM: "INSTAGRAM",
  OTHER: "OTHER",
  TWITTER: "TWITTER",
  TIKTOK: "TIKTOK",
};

export const titleHeaderFilter = {
  POST_CELEB: "셀럽 피드 관리",
  CHAT_CELEB: "셀럽 채팅 관리",
  APPECPT_CELEB: "셀럽 승인",
  FOLLOW_CELEB: "셀럽 팔로우 관리",
  SUBCRIPTION_CELEB: "셀럽 구독 관리",
  MONTHLY_SALE: "월 매출 통계",
  DAILY_SALE: "일 매출 통계",
  ACCUMULATED_STAR_CELEB: "코인 적립 통계",
  PURCHASE_HISTORY: "스타 구매내역",
  BLOCK_USER: "차단회원",
  REPORT_USER: "신고회원",
  PROFILE_IMAGE: "피드 승인",
  LIST_USER: "회원 목록",
  SETTING_DEFAULT_MESS: "셀럽 자동메세지 설정관리",
};

export const keyHeaderFilter = {
  LIST_USER: "list_user",
  LIST_COIN: "list_coin",
  SETLETMENT: "SETLETMENT",
};

export const typeDetailConversation = {
  GROUP: "GROUP",
  PERSONAL: "PERSONAL",
};

export const userType = {
  NORMAL: "NORMAL",
  CELEB: "CELEBRITY",
};

export const headerFilter = {
  Post: {
    seacrhTime: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "created_at_unix_timestamp",
        label: "등록날짜",
      },
      {
        value: "day_placed",
        label: "예약날짜",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
    subscriber: [
      {
        value: "ALL",
        checked: true,
        name: "ALL",
        label: "전체",
      },
      {
        value: "BROZEN",
        checked: false,
        name: "BROZEN",
        label: "브론즈",
      },
      {
        value: "SLIVER",
        checked: false,
        name: "SLIVER",
        label: "실버",
      },
      {
        value: "GOLD",
        checked: false,
        name: "GOLD",
        label: "골드",
      },
      {
        value: "PLATINUM",
        checked: false,
        name: "PLANTINUM",
        label: "플래티넘",
      },
    ],
    memeberInfo: [
      {
        value: "$user.nickname$",
        label: "셀럽닉네임",
      },
      {
        value: "$user.identified_id$",
        label: "셀럽아이디",
      },
    ],
    publics: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "FREE",
        label: "모두공개",
      },
      {
        value: "FREE_SUBSCRIBER",
        label: "구독",
      },
      {
        value: "SUBSCRIBE",
        label: "유료",
      },
    ],
    paids: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "FREE_SUBSCRIBER",
        label: "구독자무료",
      },
      {
        value: "SUBSCRIBE",
        label: "구독자유료",
      },
    ],
  },
  Supcription: {
    seacrhTime: [
      {
        value: "created_at_unix_timestamp",
        label: "구독시작일",
      },
      {
        value: "expire_at_unix_timestamp",
        label: "구독종료일",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "셀럽남자",
      },
      {
        value: "FEMALE",
        label: "셀럽여자",
      },
      {
        value: "SUB_MALE",
        label: "구독남자",
      },
      {
        value: "SUB_FEMALE",
        label: "구독여자",
      },
    ],
    memeberInfo: [
      {
        value: "$pack_sub.celeb.nickname$",
        label: "셀럽닉네임",
      },
      {
        value: "$pack_sub.celeb.identified_id$",
        label: "셀럽아이디",
      },
      {
        value: "$user.nickname$",
        label: "구독자 닉네임 ",
      },
      {
        value: "$user.identified_id$",
        label: "구독자 아이디",
      },
    ],
    type_subscriber: [
      {
        value: "ALL",

        label: "전체",
      },
      {
        value: "BROZEN",

        label: "브론즈",
      },
      {
        value: "SLIVER",

        label: "실버",
      },
      {
        value: "GOLD",

        label: "골드",
      },
      {
        value: "PLATINUM",

        label: "플래티넘",
      },
    ],
    subscriber_status: [
      {
        value: "ALL",

        label: "전체",
      },
      {
        value: "SUBSCRIBE",

        label: " 구독중",
      },
      {
        value: "UNSUBSCRIBE",

        label: "구독종료",
      },
    ],
  },

  Follow: {
    seacrhTime: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "$user.created_at_unix_timestamp$",
        label: "신청날짜",
      },
      {
        value: "$user.last_online$",
        label: "최신로그인",
      },
      {
        value: "created_at_unix_timestamp",
        label: "시작날짜",
      },
      {
        value: "deleted_at",
        label: "종료일",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "셀럽남자",
      },
      {
        value: "FEMALE",
        label: "셀럽여자",
      },
      {
        value: "SUB_MALE",
        label: "회원남자",
      },
      {
        value: "SUB_FEMALE",
        label: "회원여자",
      },
    ],
    memeberInfo: [
      {
        value: "$user.nickname$",
        label: "셀럽닉네임",
      },
      {
        value: "$user.identified_id$",
        label: "셀럽아이디",
      },
      {
        value: "$followed.nickname$",
        label: "회원닉네임",
      },
      {
        value: "$followed.identified_id$",
        label: "회원아이디",
      },
    ],
    memeberFollow: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "FOLLOW",
        label: "팔로우",
      },
      {
        value: "UNFOLLOW",
        label: "언팔로우",
      },
    ],
  },
  chat: {
    memeberInfo: [
      {
        value: "$admin.nickname$",
        label: "셀럽닉네임",
      },
      {
        value: "$admin.identified_id$",
        label: "셀럽아이디",
      },
      {
        value: "$other.nickname$",
        label: "회원닉네임",
      },
      {
        value: "$other.identified_id$",
        label: "회원아이디",
      },
    ],
    statusChatArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "SENT",
        label: "발송",
      },
      {
        value: "CHATING",
        label: "대화중",
      },
      {
        value: "END_CHAT",
        label: "대화종료",
      },
    ],
  },
  BlockUser: {
    userType: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: userType.NORMAL,
        label: "회원",
      },
      {
        value: userType.CELEB,
        label: "셀럽",
      },
    ],
  },
  ProfileImage: {
    type: [
      { value: "ALL", label: "전체" },
      { value: "AVATAR", label: "메인사진" },
      { value: "OTHER", label: "기타사진" },
    ],
    userType: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: userType.NORMAL,
        label: "회원",
      },
      {
        value: userType.CELEB,
        label: "셀럽",
      },
    ],
    limit: [
      {
        value: 20,
        label: 20,
      },
      {
        value: 50,
        label: 50,
      },
      {
        value: 100,
        label: 100,
      },
      {
        value: 200,
        label: 200,
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
  },

  report: {
    typeReport: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "OBSCENE_POSTS_OR_REMARKS",
        label: "음란성포스트 또는 발언",
      },
      {
        value: "IMPERSONATING_OTHERS_AND_STEALING",
        label: "타인사칭 및 도용",
      },
      {
        value: "SLANDER_AND_INAPPROPRIATE_REMARKS",
        label: "비방 및 부적절한 단어 ",
      },
    ],
    SatusReport: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "PENDING",
        label: "미처리",
      },
      {
        value: "DONE",
        label: "처리완료",
      },
    ],
    userType: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: userType.NORMAL,
        label: "회원",
      },
      {
        value: userType.CELEB,
        label: "셀럽",
      },
    ],
  },
  listUser: {
    statusAccount: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "NORMAL",
        label: "정상",
      },
      {
        value: "BLOCK",
        label: "계정정지",
      },
      {
        value: "DELETE",
        label: "탈퇴",
      },
    ],
    authenticateAccount: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "IS_AUTHENTICATED",
        label: "인증",
      },
      {
        value: "NOT_AUTHENTICATED",
        label: "미인증",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
    csLevel: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "NORMAL",
        label: "정상",
      },
      {
        value: "NOTE",
        label: "주의",
      },
      {
        value: "DANGER",
        label: "위험",
      },
    ],
    searchResults: [
      {
        value: 20,
        label: 20,
      },
      {
        value: 50,
        label: 50,
      },
      {
        value: 100,
        label: 100,
      },
      {
        value: 200,
        label: 200,
      },
    ],
    pushVideo: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "ON",
        label: "ON",
      },
      {
        value: "OFF",
        label: "OFF",
      },
    ],
    userType: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: userType.NORMAL,
        label: "회원",
      },
      {
        value: userType.CELEB,
        label: "셀럽",
      },
    ],
    pushChat: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "ON",
        label: "ON",
      },
      {
        value: "OFF",
        label: "OFF",
      },
    ],
    accept: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "PENDING",
        label: "승인대기",
      },
      {
        value: "ACCEPT",
        label: "승인완료",
      },
      {
        value: "DENY",
        label: "비승인",
      },
    ],
  },
  settingDefaultMess: {
    seacrhTime: [
      {
        value: "processed_at_unix_timestamp",
        label: "신청일",
      },
      {
        value: "last_online",
        label: "최근 접속일",
      },
    ],
    over_19: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: false,
        label: "미인증",
      },
      {
        value: true,
        label: "인증",
      },
    ],
    statusAccount: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "NORMAL",
        label: "정상",
      },
      {
        value: "BLOCK",
        label: "계정정지",
      },
      {
        value: "DELETE",
        label: "탈퇴",
      },
    ],
    memberInfoSelect: [
      {
        value: "nickname",
        label: "닉네임",
      },
      {
        value: "identified_id",
        label: "ID",
      },
      {
        value: "agency_member_no",
        label: "고유번호",
      },
    ],
  },
  listCoin: {
    seacrhTime: [
      {
        value: "created_at_unix_timestamp",
        label: "신청일",
      },
      {
        value: "last_online",
        label: "최근 접속일",
      },
    ],
    statusAccount: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "NORMAL",
        label: "정상",
      },
      {
        value: "BLOCK",
        label: "계정정지",
      },
      {
        value: "DELETE",
        label: "탈퇴",
      },
    ],
    memberInfoSelect: [
      {
        value: "$receiver.nickname$",
        label: "닉네임",
      },
      {
        value: "$receiver.identified_id$",
        label: "ID",
      },
    ],

    reason: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "VIDEO_CALL",
        label: "영상채팅",
      },
      {
        value: "TEXT",
        label: "문자채팅",
      },

      {
        value: "BUY_POST",
        label: "피드구매",
      },
      {
        value: "GIFT_FROM_VIDEO",
        label: "영상채팅 중 선물",
      },
      {
        value: "GIFT",
        label: "선물",
      },
    ],
  },
  PaymentUserProfile: {
    seacrhTime: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "created_at_unix_timestamp",
        label: "문의날짜",
      },
      {
        value: "updated_at_unix_timestamp",
        label: "답변날짜",
      },
    ],
    userType: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: userType.NORMAL,
        label: "회원",
      },
      {
        value: userType.CELEB,
        label: "셀럽",
      },
    ],
    statusPayment: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "SUCCESS",
        label: "성공",
      },
      {
        value: "REQUEST",
        label: "요청",
      },
      {
        value: "FAIL",
        label: "실패",
      },
    ],
    methodPayment: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "IAP_GOOGLE",
        label: "구글",
      },
      {
        value: "IAP_APPLE",
        label: "애플",
      },
    ],
  },
  StarUserProfile: {
    state_revenue: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "IN",
        label: "IN",
      },
      {
        value: "OUT",
        label: "OUT",
      },
    ],
    reason: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "BUY_STAR",
        label: "스타 구매 ",
      },
      {
        value: "MANAGER_PAYMENT",
        label: "관리자지급",
      },

      {
        value: "VIDEO_CALL",
        label: "영상채팅",
      },
      {
        value: "SUBSCRIPTION",
        label: "구독",
      },
      {
        value: "GIFT",
        label: "선물",
      },
      {
        value: "TEXT",
        label: "문자채팅",
      },
      {
        value: "BUY_FEED",
        label: "피드구매",
      },
    ],
  },
  ListCointUserProfile: {
    memeberInfo: [
      {
        value: "$sender.identified_id$",
        label: "회원아이디",
      },
      {
        value: "$sender.nickname$",
        label: "회원닉네임",
      },
    ],
    reason: [
      {
        value: "ALL",
        label: "전체",
      },
      // {
      // 	value: "TEXT",
      // 	label: "TEXT",
      // },
      {
        value: "VIDEO_CALL",
        label: "영상채팅",
      },
      {
        value: "SUBSCRIPTION",
        label: "구독",
      },
      {
        value: "GIFT",
        label: "선물",
      },
      {
        value: "CHATTING",
        label: "문자채팅",
      },
      {
        value: "BUY_POST",
        label: "피드구매",
      },
    ],
  },
  ListFeedUserProfile: {
    memeberInfo: [
      {
        value: "identified_id",
        label: "아이디",
      },
      {
        value: "nickname",
        label: "닉네임",
      },
    ],
    typePost: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "IMAGE",
        label: "사진",
      },
      {
        value: "VIDEO",
        label: "영상",
      },
      // {
      //   value: "ALBUM",
      //   label: "앨범",
      // },
    ],
    pruchasePeriod: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "ONE_DAY",
        label: "하루",
      },
      {
        value: "ONE_WEEK",
        label: "일주일",
      },
      {
        value: "ONE_MONTH",
        label: "한달",
      },
      {
        value: "NO_LIMIT",
        label: "무제한",
      },
    ],
    setting: [
      {
        value: "ALL",
        label: "전체",
      },
      // {
      //   value: "ONE_TIME",
      //   label: "유료",
      // },
      {
        value: "PAID",
        label: "유료",
      },
      {
        value: "FREE",
        label: "무료",
      },
    ],
    seacrhTime: [
      // {
      //   value: "ALL",
      //   label: "전체",
      // },
      {
        value: "created_at_unix_timestamp",
        label: " 등록날짜",
      },
      {
        value: "last_date_purchase",
        label: "구매날짜",
      },
    ],
  },
  ListFollowUserProfile: {
    memeberInfo: [
      {
        value: "$user.identified_id$",
        label: "회원아이디",
      },
      {
        value: "$user.nickname$",
        label: "회원닉네임",
      },
    ],
    memeberInfo2: [
      {
        value: "$user.identified_id$",
        label: " 회원아이디",
      },
      {
        value: "$user.nickname$",
        label: "회원닉네임",
      },
    ],
    status: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: true,
        label: "팔로우",
      },

      {
        value: false,
        label: "언팔로우",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
    seacrhTime2: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "created_at_unix_timestamp",
        label: " 구독시작날짜",
      },
      {
        value: "expire_at_unix_timestamp",
        label: "구독종료날짜",
      },
    ],
    seacrhTime: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "created_at_unix_timestamp",
        label: "팔로우",
      },
      {
        value: "updated_at_unix_timestamp",
        label: "언팔로우",
      },
    ],
    type: [
      {
        value: "ALL",

        label: "전체",
      },
      {
        value: "SUBSCRIBE",

        label: "구독취소",
      },
      {
        value: "UNSUBSCRIBE",

        label: "구독",
      },
    ],
    type_subscriber: [
      {
        value: "ALL",

        label: "전체",
      },
      {
        value: "BROZEN",

        label: "브론즈",
      },
      {
        value: "SLIVER",

        label: "실버",
      },
      {
        value: "GOLD",

        label: "골드",
      },
      {
        value: "PLATINUM",

        label: "플래티넘",
      },
    ],
  },
  setletment: {
    seacrhTime: [
      {
        value: "created_at_unix_timestamp",
        label: "신청일",
      },
      {
        value: "processed_at_unix_timestamp",
        label: "처리날",
      },
    ],
    accept: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "PROCESS",
        label: "신청",
      },
      {
        value: "ACCEPT",
        label: "완료",
      },
      {
        value: "DENY",
        label: "취소",
      },
    ],
    statusAccount: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "NORMAL",
        label: "정상",
      },
      {
        value: "BLOCK",
        label: "계정정지",
      },
      {
        value: "DELETE",
        label: "탈퇴",
      },
    ],
    state: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "DENY",
        // label: "DENY",
        label: "취소",
      },
      {
        value: "ACCEPT",
        // label: "ACCEPT",
        label: "완료",
      },
      {
        value: "PROCESS",
        // label: "PROCESS",
        label: "신청",
      },
      {
        value: "AGENCY_PENDING",
        // label: "AGENCY PENDING",
        label: "대행사 대기 중",
      },
    ],
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
    memberInfoSelect: [
      {
        value: "$user.nickname$",
        label: "닉네임",
      },
      {
        value: "$user.identified_id$",
        label: "ID",
      },
      {
        value: "$user.agency_member_no$",
        label: "고유번호",
      },
    ],
  },
  listCelebDonate: {
    genderArr: [
      {
        value: "ALL",
        label: "전체",
      },
      {
        value: "MALE",
        label: "남자",
      },
      {
        value: "FEMALE",
        label: "여자",
      },
    ],
    memberInfoSelect: [
      {
        value: "nickname",
        label: "닉네임",
      },
      {
        value: "identified_id",
        label: "ID",
      },
    ],
  },
};

export const typeCoin = {
  ATTENDANCE: "ATTENDANCE",
  BUY_POST: "BUY_POST",
  SEND_GIFT: "SEND_GIFT",
  BUY_SECRET_ALBUM: "BUY_SECRET_ALBUM",
  BUY_SECRET_MESSAGE: "BUY_SECRET_MESSAGE",
  VIDEO_CALL: "VIDEO_CALL",
};
