import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import ExportCSV from "./ExportCSV";

import "./style.css";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@mui/material";
import useWindowSize from "src/hooks/useWindowSize";
import TransitionGroupData from "./TransitionGroupData";
import { styled } from "@mui/material/styles";
import { useCallback } from "react";
import { useState } from "react";
import { green, red } from "@mui/material/colors";

const NoData = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "200px",
}));
function DataTable({
  columns,
  items,
  total,
  page,
  currentPage,
  changePage,
  limit,
  setLimit,
  fileName,
  handleMany,
  autoHeight,
  handleDelete,
  showLimit,
  showLimitV2 = false,
  checkboxSelection,
  onSelectionModelChange,
  selectionModel,
  getRowId,
  handleDeny,
  handleAccept,
  hiddenDeny = false,
  disable = false,
}) {
  const getRowHeight = useCallback(() => {
    return "auto";
  }, []);
  const size = useWindowSize();
  return (
    <Box
      sx={{
        width: "100%",
        "& .bold": {
          fontWeight: 600,
        },
        "& .MuiDataGrid-virtualScroller": {
          background: "white",
        },
        "& .MuiDataGrid-columnHeaderRow": {
          background: "f6f6f6",
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="headerDataTable"
      >
        <Grid item order={{ xs: 2, md: 1 }}>
          <div>
            {selectionModel && selectionModel.length > 0
              ? selectionModel.length + " rows selected"
              : "총 " + total + "건" || 0}
          </div>
        </Grid>

        <Grid item order={{ xs: 1, md: 2 }}>
          <Grid container spacing={2} direction="row">
            {selectionModel && (
              <Grid item>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sx={{
                      cursor:
                        selectionModel.length > 0 ? "default" : "not-allowed  ",
                    }}
                  >
                    <Button
                      variant="outlined"
                      sx={{
                        background: green[100],
                        width: 141,
                        height: 42,
                        borderRadius: "0.5rem",
                        border: `1px solid ${green[400]}`,
                        color: "black",
                        "&:hover": {
                          background: green[200],
                          border: `1px solid ${green[400]}`,
                        },
                      }}
                      disabled={
                        selectionModel.length > 0 && !disable ? false : true
                      }
                      onClick={() => handleAccept(selectionModel)}
                    >
                      체크 일괄신청
                    </Button>
                  </Grid>
                  {!hiddenDeny && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        sx={{
                          background: red[100],
                          width: 112,
                          height: 42,
                          borderRadius: "0.5rem",
                          border: `1px solid ${red[400]}`,
                          color: "black",
                          "&:hover": {
                            background: red[200],
                            border: `1px solid ${red[400]}`,
                          },
                        }}
                        onClick={() => handleDeny(selectionModel)}
                      >
                        체크 일괄취소
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {handleDelete && (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#CECECE",
                    width: 112,
                    height: 42,
                    borderRadius: "0.5rem",
                    border: "1px solid #B1B1B1",
                    color: "black",
                  }}
                >
                  회원탈퇴b
                </Button>
              </Grid>
            )}
            {handleMany && (
              <Grid item>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#FFDBDB",
                    width: 141,
                    height: 42,
                    borderRadius: "0.5rem",
                    border: "1px solid #FEA0A0",
                    color: "black",
                  }}
                  onClick={handleMany.action}
                >
                  {handleMany.title}
                </Button>
              </Grid>
            )}
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <ExportCSV csvData={items} fileName={fileName} />
                </Grid>
                {(showLimit === false ? showLimit : true) && (
                  <Grid item>
                    <FormControl sx={{ height: "42px" }}>
                      <Select
                        className="select"
                        value={limit.value}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        onChange={(e) => limit.set(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {(showLimitV2 === false ? showLimitV2 : true) && (
                  <Grid item>
                    <FormControl sx={{ height: "42px" }}>
                      <Select
                        className="select"
                        value={limit}
                        sx={{
                          "& legend": { display: "none" },
                          "& fieldset": { top: 0 },
                        }}
                        onChange={(e) => setLimit(e.target.value)}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {size.width > 720 ? (
        <>
          <DataGrid
            sx={{
              "& .MuiDataGrid-cell--textLeft": {
                justifyContent: "center",
              },
            }}
            autoHeight
            getRowHeight={autoHeight && getRowHeight}
            disableExtendRowFullWidth
            disableColumnFilter
            disableSelectionOnClick
            disableColumnMenu
            hideFooter
            showCellRightBorder
            showColumnRightBorder
            columns={columns}
            rows={items}
            getRowId={getRowId}
            checkboxSelection={checkboxSelection}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectionModel}
          />
          <div className="pagination">
            <Pagination
              count={page}
              page={currentPage}
              variant="outlined"
              shape="rounded"
              onChange={changePage}
            />
          </div>
        </>
      ) : items.length > 0 ? (
        items.map((item, index) => (
          <TransitionGroupData item={item} columns={columns} />
        ))
      ) : (
        <NoData>No data</NoData>
      )}
    </Box>
  );
}
export default React.memo(DataTable);
