import axiosClient from "./axiosClient";
const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all", {"receiver": ["$all"]}, {"sender": ["$all"]}, {"call_history": ["duration", "start_time", "end_time"]}]';
const table = "usage_star";

const Api = {
  getAll: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}?${fields}&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    return axiosClient.get(url);
  },

  // update: (body, id) => {
  //   const url = `${HOSTNAME}/${table}/${id}`;
  //   console.log("url...", url);
  //   return axiosClient.put(url, body, {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // },
};

export default Api;
