import jwt_decode from "jwt-decode";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  BrowserRouter,
} from "react-router-dom";
// import Login from "./views/pages/login/Login"
import { icons } from "./assets/icons";
import TheLayout from "./Layout/TheLayout";
import { LastLocationProvider } from "react-router-last-location";
import { LoadingProvider } from "./providers/LoadingProvider";
import { showError } from "./util/error";
import routes from "./routes";
import "./scss/style.scss";
import "./app.css";

React.icons = icons;
const Login = React.lazy(() => import("./Pages/login/Login"));

function App() {
  const employee = useSelector((state) => state.employee);
  const token = localStorage.getItem("token");
  const tokenDecode = token ? jwt_decode(token) : "";
  let expired = false;
  if (new Date(tokenDecode.exp).getTime() <= Date.now()) {
    expired = true;
  }
  if (expired) {
    localStorage.clear();
    showError("Your login session has been expired!");
  }

  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
      ...Loading
    </div>
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        employee.length > 0 || localStorage.getItem("token") ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  return (
    <div>
      <HashRouter basename="/">
        <LoadingProvider>
          <LastLocationProvider>
            <Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login" component={Login} />
                <PrivateRoute component={TheLayout} />
              </Switch>
            </Suspense>
          </LastLocationProvider>
        </LoadingProvider>
      </HashRouter>
    </div>
  );
}

export default App;
