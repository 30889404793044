import React, { useEffect, useState, useContext } from "react";
import GetApi from "src/api/ListSetletmentApi";
import DataTable from "src/components/DataTable";
import GetApiUser from "src/api/ListUserApi";

import {
  convertTimeZone,
  convertVerify19,
  formatNumber,
} from "src/util/convert";
import { LoadingContext } from "src/providers/LoadingProvider";
import { showError, showSuccess } from "src/util/error";

import ColumnListCelebDonate from "src/components/Columns/ColumnListCelebDonate/ColumnListCelebDonate";
import { useForm } from "react-hook-form";
import { filterListCelebDonate } from "src/util/setFilter";
import { Button } from "@mui/material";
import CelebInfo from "src/components/DetailCele";
import FilterListCelebDonate from "src/components/FilterListCelebDonate/FilterListCelebDonate";

const API = GetApi;
const APIUSER = GetApiUser;
const ListCelebDonate = () => {
  const { register, handleSubmit, setValue } = useForm();
  const agency = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const { id } = agency || "";
  const [openDetail, setOpenDetail] = useState(false);
  const [item, setItem] = useState("");
  const handleDetail = (item) => {
    setItem(item);
    setOpenDetail(true);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [arrId, setArrId] = useState([]);

  const [filter, setFilter] = useState({});
  const arr = [];
  const { showLoading, hideLoading } = useContext(LoadingContext);

  const handleAccept = async (arr) => {
    try {
      const response = await API.acceptCeleb({
        user_ids: arr,
      });
      console.log(response);
      showSuccess("Success");
      fetchAll();
    } catch (error) {
      showError(error.response.data.message || "Had Unknown Error");
    }
  };
  const columns = ColumnListCelebDonate(handleAccept, handleDetail);

  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const fetchAll = async () => {
    showLoading();
    try {
      const response = await APIUSER.getAll(
        currentPage,
        limit,
        JSON.stringify({
          ...filter,
          user_type: "CELEBRITY",
          register_status: "ACCEPT",
          agency_id: agency?.id || null,
          donated_money: { $gte: 50000 },
        })
      );
      setTotal(response?.results?.objects.count);
      if (response.code === 200) {
        // xu li pagination
        let pages = Math.max(
          Math.ceil(response.results.objects.count / limit),
          1
        );
        setPage(pages);
        response.results.objects.rows.map((val, idx) => {
          arr.push({
            id: val.id,
            no: idx + (currentPage.value - 1) * limit.value + 1,
            avatar: val?.avatar,
            nickname: val?.nickname || "-",
            gender: val?.sex || "",
            phone: val?.phone ? `${val.phone}` : "-",
            image_card: val?.image_card,
            state_register: val?.state_register ?? "",
            realname: val?.name || "-",
            agency_code: val?.agency_code || "-",
            identified_id: val?.identified_id || "-",
            age_verified: convertVerify19(val?.is_over_19),
            status: val.state,
            wallet: formatNumber(val?.wallet) || 0,
            status_account: val?.status_account || "-",
            intermediary_fee: val?.intermediary_fee || 0,
            hasgtag:
              val?.user_hashtags?.map((item) => "#" + item?.hashtag?.title) ||
              "-",
            user_type: val?.user_type,
            subscribeCount: formatNumber(val?.subscribers_count) || 0,
            follower_count: formatNumber(val?.follower_count) || 0,
            email: val.email || "",
            birthday: convertTimeZone(
              parseInt(val?.birthday) || val.created_at_unix_timestamp
            ),

            created_at_unix_timestamp: convertTimeZone(
              parseInt(val?.processed_at_unix_timestamp) || val.created_at
            ),
            processing_date: val?.last_online
              ? convertTimeZone(parseInt(val?.last_online))
              : "-",
            data: val || "",
            donate:
              (
                (val?.donated_money || 0) + (val?.subscribe_revenue || 0)
              ).toLocaleString() || 0,
          });
        });
      }
    } catch (error) {
      hideLoading();
      showError(error);
    } finally {
      hideLoading();
      setItems(arr);
    }
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  const onSubmit = (data) => {
    console.log(data);
    const filter = filterListCelebDonate(data, id);
    setFilter(filter);
    setCurrentPage(1);
  };
  useEffect(() => {
    fetchAll();
  }, [currentPage, limit, filter]);
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} style={{ marginBottom: 20 }}>
        <FilterListCelebDonate register={register} setValue={setValue} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              background: "black",
              padding: "6px 16px",
              width: "222px",
              height: "56px",
              borderRadius: "10px",
            }}
            type="submit"
          >
            검색
          </Button>
        </div>
      </form>

      <DataTable
        columns={columns}
        total={total}
        items={items}
        page={page}
        currentPage={currentPage}
        changePage={handleChangePage}
        limit={limit}
        fileName="SETLETMENT"
        checkboxSelection={true}
        onSelectionModelChange={(e) => setArrId(e)}
        selectionModel={arrId}
        showLimit={false}
        showLimitV2={true}
        setLimit={handleSetLimit}
        handleAccept={handleAccept}
        hiddenDeny
      />
      {openDetail && <CelebInfo setOpenDetail={setOpenDetail} item={item} />}
    </div>
  );
};

export default React.memo(ListCelebDonate);
