import React, { useEffect, useState } from "react";
import "./style.scss";
import { Modal, Stack } from "@mui/material";
import { getAgeUser } from "src/util/Utils";
import DetailPayment from "./DetailPayment.js";
import DetailInfo from "./DetailInfo.js";
import DetailUsageStar from "./DetailUsageStar.js";
import DetailListCoin from "./DetailListCoin.js";
import DetailBuyFeed from "./DetailBuyFeed";
import DetailFollow from "./DetailFollow";
import DetailSubcription from "./DetailSubcription";
import DetailInquiry from "./DetailInquiry";
import { convertTextToKo } from "src/util/convert";
function DetailCeleb({ setOpenDetail, item }) {
  const { data } = item;
  const menuData = [
    {
      label: "셀럽정보",
      value: "CelebInfo",
      comp: DetailInfo,
    },
    {
      label: "결제",
      value: "Payment",
      comp: DetailPayment,
    },
    {
      label: "스타",
      value: "Star",
      comp: DetailUsageStar,
    },
    {
      label: "코인 적립 리스트",
      value: "ListCoin",
      comp: DetailListCoin,
    },
    {
      label: "피드 리스트",
      value: "FeedList",
      comp: DetailBuyFeed,
    },
    {
      label: "팔로우 리스트",
      value: "FollowList",
      comp: DetailFollow,
    },
    {
      label: "구독 리스트",
      value: "SupcriptionList",
      comp: DetailSubcription,
    },
    {
      label: "문의사항",
      value: "InquiriUserProfile",
      comp: DetailInquiry,
    },
  ];
  const [tab, setTab] = useState("CelebInfo");
  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === "Escape") {
        setOpenDetail(false);
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);
  return (
    <Modal className="wrapper" open={true} onClose={() => setOpenDetail(false)}>
      <Stack className="container">
        <h1 className="title1">셀럽 상세 프로필</h1>
        <button className="button" onClick={() => setOpenDetail(false)}>
          X
        </button>
        <div className="tabBar">
          {menuData.map((item) => (
            <div
              className={
                item.value === tab ? "buttonTabBar active" : "buttonTabBar"
              }
              key={item.value}
              onClick={() => setTab(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <Stack direction="row" className="container1">
          <div className="label1">셀럽 ID</div>
          <div className="value1">{data.identified_id}</div>
          <div className="label1">닉네임</div>
          <div className="value1">{data.nickname}</div>
          <div className="label1">나이</div>
          <div className="value1">{getAgeUser(item.birthday)}</div>
          <div className="label1">성별</div>
          <div className="value1">{convertTextToKo(data.sex)}</div>
          <div className="label1">uuid</div>
          <div className="value1"></div>
        </Stack>
        {menuData.map((tabMenu) => {
          const { comp: Component } = tabMenu;
          return (
            <>
              {tabMenu.value === tab && (
                <Component id={data.id} key={tabMenu.value} item={item} />
              )}
            </>
          );
        })}
      </Stack>
    </Modal>
  );
}

export default DetailCeleb;
