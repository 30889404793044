import React, { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import FieldCustom from "./Fields/FieldCustom";
import InputCustom from "./Fields/InputCustom";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { handleSetAllTitles } from "src/util/Utils";
// import {  } from "src/util/convert";
import InputTextareaCustom from "./Fields/InputTextareaCustom";
import { useForm } from "react-hook-form";
import { convertTextToKo } from "src/util/convert";

function DetailInquiry({ id }) {
  const API = GetApi;
  const { register, setValue } = useForm();

  const [title, setTitle] = useState([{}]);
  const [displayData, setDisplayData] = useState();
  const [totalData, setTotalData] = useState();
  const handleGetDetailInquiry = async () => {
    try {
      const res = await API.getUserInquiry(id);
      setTotalData(res?.results?.objects?.rows);
    } catch (error) {
      throw error;
    }
  };
  const handleSetDisplayData = (value) => {
    const index = title.findIndex((item) => item.id === value);
    setDisplayData(title[index]);
  };
  useEffect(() => {
    handleGetDetailInquiry(id);
  }, []);
  useEffect(() => {
    setTitle(handleSetAllTitles(totalData));
  }, [totalData]);
  useEffect(() => {
    setDisplayData(title[0]);
  }, [title]);
  return (
    <div>
      <div className="formContainer">
        <div style={{ width: "100%", height: "50px", margin: "5px" }}>
          <FieldCustom label="제목">
            <SelectCustomInput
              setValue={setValue}
              name="title"
              register={register("title")}
              data={title}
              width="100%"
              handleSetDisplayData={handleSetDisplayData}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom label="셀럽 ID">
            <InputCustom
              readOnly
              style={{ outline: "none" }}
              value={displayData?.user_identified_id}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom label="셀럽 닉네임">
            <InputCustom
              readOnly
              style={{ outline: "none" }}
              value={displayData?.user_nickname}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom label="문의종류">
            <InputCustom
              readOnly
              style={{ outline: "none" }}
              value={convertTextToKo(displayData?.type)}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom label="답변날짜">
            <InputCustom
              readOnly
              style={{ outline: "none" }}
              value={displayData?.processed_at_unix_timestamp}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2" style={{ height: "200px" }}>
          <FieldCustom alignItems="" label="문의내용 ">
            <InputTextareaCustom
              readOnly
              style={{ outline: "none", resize: "none" }}
              value={displayData?.content}
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom label="문의날짜">
            <InputCustom
              readOnly
              style={{ outline: "none" }}
              value={displayData?.created_at_unix_timestamp}
            />
          </FieldCustom>
        </div>

        <div className="formContainer2" style={{ height: "200px" }}>
          <FieldCustom alignItems="" label="답변내용">
            <InputTextareaCustom
              readOnly
              value={displayData?.answer}
              style={{ outline: "none", resize: "none" }}
            />
          </FieldCustom>
        </div>
      </div>
    </div>
  );
}

export default DetailInquiry;
