import React, { useEffect, useState, useContext } from "react";
import ListUserFilter from "src/components/HeaderFilterInput/SettingDefaultMess";
import TheHeaderDefault from "src/Layout/TheHeaderDefault";
import useFilters from "src/hooks/useFilter";
import GetApi from "src/api/ListUserApi";
import DataTable from "src/components/DataTable";
import { keyHeaderFilter } from "src/util/data";
import Tooltip from "@mui/material/Tooltip";
import celebBadge from "src/assets/img/celeb_badge.svg";
import {
  convertTextToKo,
  convertTimeZone,
  convertVerify19,
} from "src/util/convert";
import { getAgeUser } from "src/util/Utils";
import { LoadingContext } from "src/providers/LoadingProvider";
import { showError } from "src/util/error";
import CelebInfo from "src/components/DetailCele";

const API = GetApi;
const ListUser = () => {
  const [openDetail, setOpenDetail] = useState(false);
  const [item, setItem] = useState("");
  const agency = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;
  const [items, setItems] = useState([]);
  const [total, settotal] = useState(0);
  const [filter, setfilter] = useState({
    // user_type: "CELEBRITY",
    // register_status: "ACCEPT",
    // agency_id: agency?.id || null,
  });
  const arr = [];
  const { showLoading, hideLoading } = useContext(LoadingContext);
  const filterState = useFilters();
  const { LIMITED, currentPage, spages } = filterState;
  const handleDetail = (item) => {
    setItem(item);
    setOpenDetail(true);
  };
  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "고유번호",
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "nickname",
      headerName: "셀럽 닉네임(ID)",
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ textAlign: "center", cursor: "pointer" }}
            onClick={() => handleDetail(params.row)}
          >
            <p>
              {params.row.nickname}{" "}
              <span>
                <img src={celebBadge} />
              </span>
            </p>
            <p>({params.row.identified_id})</p>
          </div>
        );
      },
    },
    // {
    //   field: "realname",
    //   headerName: "이름",
    //   headerAlign: "center",
    //   sortable: false,
    // },
    {
      field: "birthday",
      headerName: "나이",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{getAgeUser(params.row.birthday)}</p>
          </div>
        );
      },
    },
    {
      field: "gender",
      headerName: "성별",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <div style={{ textAlign: "center" }}>
            <p>{params.row.gender == "MALE" ? "남자" : "여자"}</p>
          </div>
        );
      },
    },
    {
      field: "hasgtag",
      headerName: "해시태그",
      flex: 1,
      minWidth: 200,
      sortable: false,
      headerAlign: "center",
    },

    {
      field: "created_at_unix_timestamp",
      headerName: "셀럽 신청일",
      flex: 1,
      minWidth: 150,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.created_at_unix_timestamp}>
              <p>{params.row.created_at_unix_timestamp}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "processing_date",
      headerName: "최근 접속일",
      minWidth: 150,
      flex: 1,
      sortable: false,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={params.row.processing_date}>
              <p>{params.row.processing_date}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "subscribeCount",
      headerName: "구독수",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "follower_count",
      headerName: "팔로워수",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "age_verified",
      headerName: "성인인증",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "donate",
      headerName: "보유금액",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "intermediary_fee",
      headerName: "수수료율(%)",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
    {
      field: "status_account",
      headerName: "상태",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
    },
  ];
  const handleChangePage = (e, value) => {
    currentPage.set(value);
  };

  const fetchAll = async () => {
    showLoading();
    try {
      const response = await API.getAll(
        currentPage.value,
        LIMITED.value,
        JSON.stringify({
          ...filter,
          user_type: "CELEBRITY",
          register_status: "ACCEPT",
          agency_id: agency?.id || null,
        })
      );

      // console.log("PostList -> response", response.results.objects.rows);
      settotal(response?.results?.objects.count);
      if (response.code === 200) {
        // xu li pagination
        let pages = Math.max(
          Math.ceil(response.results.objects.count / LIMITED.value),
          1
        );
        spages.set(pages);

        response.results.objects.rows.map((val, idx) => {
          arr.push({
            id: val.id,
            // no: idx + (currentPage.value - 1) * LIMITED.value + 1,
            no: val?.agency_member_no,
            avatar: val?.avatar,
            nickname: val?.nickname || "-",
            gender: val?.sex || "",
            phone: val?.phone ? `${val.phone}` : "-",
            image_card: val?.image_card,
            state_register: val?.state_register ?? "",
            realname: val?.name || "-",
            agency_code: val?.agency_code || "-",
            identified_id: val?.identified_id || "-",
            age_verified: convertVerify19(val?.has_adult_content),
            status: val.state,
            wallet: val?.wallet || 0,
            donate:
              (
                (val?.donated_money || 0) + (val?.subscribe_revenue || 0)
              ).toLocaleString() || 0,
            status_account: convertTextToKo(val?.status_account || "-"),
            intermediary_fee: `${(val?.intermediary_fee || 0)}%`,
            hasgtag:
              val?.user_hashtags?.map((item) => "#" + item?.hashtag?.title) ||
              "-",
            user_type: val?.user_type,
            subscribeCount: (val?.subscribers_count).toLocaleString() || 0,
            follower_count: (val?.follower_count).toLocaleString() || 0,
            email: val.email || "",
            birthday: convertTimeZone(
              parseInt(val?.birthday) || val.created_at_unix_timestamp
            ),

            created_at_unix_timestamp: convertTimeZone(
              parseInt(val?.processed_at_unix_timestamp) || val.created_at
            ),
            processing_date: val?.last_online
              ? convertTimeZone(parseInt(val?.last_online))
              : "-",
            data: val || "",
          });
        });
      }
    } catch (error) {
      hideLoading();
      showError(error);
    } finally {
      hideLoading();
      setItems(arr);
    }
  };

  useEffect(() => {
    fetchAll();
  }, [currentPage.value, LIMITED.value, filter]);

  return (
    <div>
      <TheHeaderDefault
        Component={ListUserFilter}
        setFilter={setfilter}
        typeHeadeFilter={keyHeaderFilter.LIST_USER}
        initialValues={{
          search_time: "processed_at_unix_timestamp",
          // memberInfoSelect: "ALL",
          // status_account: "ALL",
          memberInfoSelect: "nickname",
          memberInfoInput: "",
          time__data: [],
        }}
      />
      <DataTable
        columns={columns}
        total={total}
        items={items}
        page={spages.value}
        currentPage={currentPage.value}
        changePage={handleChangePage}
        limit={LIMITED}
        fileName="list celeb"
      />
      {openDetail && <CelebInfo setOpenDetail={setOpenDetail} item={item} />}
    </div>
  );
};

export default ListUser;
