import React, { useEffect, useState } from "react";
import logo from "src/assets/img/new_logo.png";

import "./style.css";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar } from "@mui/material";

import { useTranslation } from "react-i18next";
import i18n from "src/translation/i18n";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const drawerWidth = 385;
const TheSidebarDefault = (props) => {
  const { container, isMobile, handleDrawerToggle } = props;
  const { mobileOpen, setMobileOpen } = isMobile;
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};
  const { username, account_holder_name, account_number, withdrawal_bank } =
    user;
  const history = useHistory();

  const theme = useTheme();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const drawer = (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        padding: "28px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          my: "52px",
        }}
      >
        <Avatar
          src={logo}
          sx={{ width: 60, height: 60, mr: "13px" }}
          variant="rounded"
        />
        <Box>
          <Typography
            // variant="h1"
            noWrap
            // component="subtitle1"
            sx={{ font: "normal normal bold 29px/33px Pretendard" }}
          >
            Fansome
          </Typography>
          <Typography
            // variant="h1"
            noWrap
            // component="subtitle2"
            sx={{ font: "normal normal medium 20px/33px Pretendard" }}
          >
            Admin System
          </Typography>
        </Box>
      </Box>
      <Box
        className="sidebar--userInfo"
        sx={{
          mt: "28px",
        }}
      >
        <Avatar sx={{ width: 70, height: 70, mr: "22px" }} />
        <div>
          <Typography sx={{ font: "normal normal 600 17px/33px Pretendard" }}>
            로그인 계정
          </Typography>
          <Typography
            sx={{ font: "normal normal medium 17px/33px Pretendard" }}
          >
            {username}
          </Typography>
        </div>
      </Box>
      <Box className="sidebar--bankUser" sx={{ mt: "auto" }}>
        <p>은행명 : {withdrawal_bank ? withdrawal_bank : ""}</p>
        <p>예금주 : {account_holder_name ? account_holder_name : ""}</p>
        <p>계좌번호 : {account_number ? account_number : ""}</p>
      </Box>
    </Box>
  );
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
    // <>
    //   <Drawer
    //     // sx={{
    //     //   md: {
    //     //     width: 720,
    //     //   },
    //     //   lg: {
    //     //     width: 256,
    //     //   },
    //     //   xl: {
    //     //     width: drawerWidth,
    //     //   },
    //     // }}
    //     className="drawerCustom"
    //     variant="persistent"
    //     anchor="left"
    //     open={open}>
    //     <div className="sidebar--titleContainer">
    //       <img src={logo} width={60} height={60} />
    //       <div className="sidebar--title">
    //         <h1>Fansome</h1>
    //         <p>Admin System</p>
    //       </div>
    //     </div>
    //     <div>
    //       <div className="sidebar-body-container">
    //         <div className="sidebar--userInfo">
    //           <Avatar />
    //           <div className="sidebar--name">
    //             <p>로그인 계정</p>
    //             <span>{username}</span>
    //           </div>
    //         </div>
    //         <div className="sidebar--bankUser">
    //           <p>은행명 : {account_holder_name ? account_holder_name : ""}</p>
    //           <p>예금주 : {withdrawal_bank ? withdrawal_bank : ""}</p>
    //           <p>계좌번호 : {account_number ? account_number : ""}</p>
    //         </div>
    //       </div>
    //     </div>
    //   </Drawer>
    // </>
  );
};

export default TheSidebarDefault;
