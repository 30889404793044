import { configureStore } from "@reduxjs/toolkit";
import sidebarShow from "./SLice/responsive";
import employee from "./SLice/Employee";
import Query from "./SLice/Query";

const rootReducer = {
  sidebarShow: sidebarShow,
  employee: employee,
  query: Query,
};

const store = configureStore({
  reducer: rootReducer,
});

export default store;
