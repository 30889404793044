import React from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  Button,
  Box,
  Checkbox,
  Stack,
} from "@mui/material";
import celebBadge from "src/assets/img/celeb_badge.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { styled } from "@mui/material/styles";
import { useState } from "react";

const CardCustom = styled(Card)(({ theme }) => ({
  ".MuiCardContent-root:last-child": {
    paddingBottom: 0,
  },
}));
const CollapseButton = styled(Button)(({ theme }) => ({
  width: "100%",
  background: "#FAFAFA 0% 0% no-repeat padding-box",
  border: "1px solid #E1E1E1",
  borderRadius: "10px",
  color: theme.palette.getContrastText("#SCSCSC"),
  backgroundColor: "#FAFAFA",
  // "&:hover": {
  //   backgroundColor: purple[700],
  // },
}));

export default function TransitionGroupData({ columns, item }) {
  const [checked, setChecked] = useState({
    id: null,
    check: false,
  });
  const handleChange = (id) => {
    setChecked({ id, check: !checked.check });
  };
  return (
    <CardCustom key={item.id} sx={{ mb: "24px", borderRadius: "20px" }}>
      <CardContent sx={{ p: 0 }}>
        <Box
          sx={{
            p: "20px",
            background: "#FAFAFA 0% 0% no-repeat padding-box",
            borderBottom: "1px solid #E6ECF3",
            borderRadius: "20px 20px 0px 0px",
          }}>
          <Stack direction={"row"}>
            <Box>
              <Checkbox />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "auto",
              }}>
              <Typography>{item.nickname}</Typography>
              <Avatar sx={{ width: 22, height: 22 }} src={celebBadge} />
              <Typography>({item.identified_id})</Typography>
            </Box>
          </Stack>
        </Box>
        <Box sx={{ p: "30px" }}>
          <Collapse
            in={checked.check && item.id === checked.id}
            collapsedSize={230}>
            {columns.map(
              (itemCol) =>
                itemCol.field !== "id" &&
                itemCol.field !== "no" && (
                  <Grid container spacing={2} sx={{ mb: "24px" }}>
                    <Grid item xs={6}>
                      {itemCol.headerName}
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "right" }}>
                      {Object.keys(item).includes(itemCol.field) &&
                        itemCol.field !== "id" &&
                        itemCol.field !== "no" &&
                        item[itemCol.field]}
                    </Grid>
                  </Grid>
                )
            )}
          </Collapse>
          <CollapseButton
            onClick={() => handleChange(item.id)}
            endIcon={
              checked.check && item.id === checked.id && checked.check ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowUpIcon />
              )
            }>
            {checked.check && item.id === checked.id && checked.check
              ? "더보기"
              : "접기"}
          </CollapseButton>
        </Box>
      </CardContent>
    </CardCustom>
  );
}
