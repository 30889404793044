import React from "react";
import "./style.scss";
function InputTextareaCustom({
  register,
  placeholder,
  width,
  height,
  ...passProps
}) {
  return (
    <textarea
      className="customScss"
      style={{ width, background: "#f8f9fb" }}
      placeholder={placeholder ? placeholder : ""}
      {...passProps}
      {...register}
    ></textarea>
  );
}

export default InputTextareaCustom;
