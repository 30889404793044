import React, { useEffect, useState } from "react";
import "./style.scss";
import { Stack } from "@mui/material";
import {
  checkPlatForm,
  checkPlatFormLink,
  getAgeUser,
  totalPayment,
} from "src/util/Utils";
import {
  convertDonatedMoney,
  convertNiceToDate,
  convertTextToKo,
  convertTimeZone,
} from "src/util/convert";
import GetApi from "src/api/ListUserApi";
function DetailInfo({ item }) {
  const API = GetApi;
  console.log("selectedID", item);
  const { data } = item;
  const [dataByID, setDataByID] = useState();
  const platForm = {
    youtube_link: data?.youtube_link,
    instagram_link: data?.instagram_link,
    twitter_link: data?.twitter_link,
    tiktok_link: data?.tiktok_link,
    other_link: data?.other_link,
  };
  const getById = async (id) => {
    try {
      const response = await API.getOne(id);
      setDataByID(response.results?.object);
    } catch (error) {
      throw error;
    }
  };
  useEffect(() => {
    console.log("dataByID", dataByID);
  }, [dataByID]);
  useEffect(async () => {
    getById(data.id);
  }, [data]);
  return (
    <Stack>
      <Stack className="container2">
        <div className="title2">사진정보</div>
        <div className="container2-1">
          <img
            src={data?.avatar[0] || data?.default_avatar}
            alt="image"
            className="image"
          />
          <Stack>
            <Stack className="container2-2" direction="row">
              <div className="label2">총 적립금액</div>
              <div className="value2">
                {(
                  Number(dataByID?.total_revenue) +
                  Number(dataByID?.donated_money) +
                  Number(data?.subscribe_revenue)
                ).toLocaleString()}
              </div>
            </Stack>
            <Stack className="container2-2" direction="row">
              <div className="label2"> 총 환급금액</div>
              <div className="value2">
                {Number(dataByID?.total_accum_only_accept).toLocaleString()}
              </div>
            </Stack>
            <Stack className="container2-2" direction="row">
              <div className="label2">출금 가능금액</div>
              <div className="value2">{convertDonatedMoney(data)}</div>
            </Stack>
          </Stack>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">기본정보</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">셀럽 ID</div>
            <div className="value3">{data.identified_id}</div>
          </div>
          <div className="container3-2">
            <div className="label3">닉네임</div>
            <div className="value3">{data.nickname}</div>
          </div>
          <div className="container3-2">
            <div className="label3">나이</div>
            <div className="value3">{getAgeUser(item.birthday)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">성별</div>
            <div className="value3">{convertTextToKo(data.sex)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">이메일</div>
            <div className="value3">{data.email}</div>
          </div>
          <div className="container3-2">
            <div className="label3">상태</div>
            <div className="value3">{convertTextToKo(data.status_account)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">관심사</div>
            <div className="value3">{item.hasgtag}</div>
          </div>
          <div className="container3-2">
            <div className="label3">파트너</div>
            <div className="value3">{data.agency?.nickname}</div>
          </div>
          <div className="container3-2">
            <div className="label3">등급</div>
            <div className="value3">{convertTextToKo(data.cs_level)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">결제</div>
            <div className="value3">{`${Number(
              dataByID?.total_won_iap
            ).toLocaleString()}/${Number(
              dataByID?.number_of_iap
            ).toLocaleString()}회`}</div>
          </div>
          <div className="container3-2">
            <div className="label3">최초 결제</div>
            <div className="value3">
              {convertTimeZone(+dataByID?.first_iap_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">최근 결제</div>
            <div className="value3">
              {convertTimeZone(+dataByID?.last_iap_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">플랫폼</div>
            <div className="value3">{checkPlatForm(platForm)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">플랫폼 주소</div>
            <div className="value3">{checkPlatFormLink(platForm)}</div>
          </div>
          <div className="container3-2">
            <div className="label3">처리상태</div>
            <div className="value3">
              {data.register_status == "ACCEPT"
                ? "승인완료"
                : data.register_status == "DENY"
                ? "비승인"
                : data.register_status == "PENDING"
                ? "승인대기"
                : "회원가입 진행중"}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">신청날짜</div>
            <div className="value3">
              {convertTimeZone(+data?.created_at_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">처리날짜</div>
            <div className="value3">
              {convertTimeZone(+data?.processed_at_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">성인 여부</div>
            <div className="value3">
              {data.has_adult_content ? "선택" : "비선택"}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">자기소개</div>
            <div className="value3">{data.about_me}</div>
          </div>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">가입 · 로그인정보</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">가입 일자</div>
            <div className="value3">
              {convertTimeZone(+data?.created_at_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">로그인 일자</div>
            <div className="value3">
              {convertTimeZone(+data?.sign_in_time_unix_timestamp)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">가입 디바이스</div>
            <div className="value3">{data?.sign_up_device}</div>
          </div>
          <div className="container3-2">
            <div className="label3">로그인 디바이스</div>
            <div className="value3">{data?.sign_in_device}</div>
          </div>
          <div className="container3-2">
            <div className="label3">가입플랫폼</div>
            <div className="value3">{data?.sign_up_platform}</div>
          </div>
          <div className="container3-2">
            <div className="label3">로그인 플랫폼</div>
            <div className="value3">{data?.sign_in_platform}</div>
          </div>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">푸시 설정</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">영상통화</div>
            <div className="value3">
              {data?.user_setting?.allow_video_call === true ? "ON" : "OFF"}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">문자채팅</div>
            <div className="value3">
              {data?.user_setting?.allow_message === true ? "ON" : "OFF"}
            </div>
          </div>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">영상통화 설정정보</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">1분당 스타</div>
            <div className="value3">{data?.user_setting?.pay_per_call}</div>
          </div>
          <div className="container3-2">
            <div className="label3">최소시간</div>
            <div className="value3">{data?.user_setting?.min_call_minutes}</div>
          </div>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">에이전시&환급정보</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">에이전시</div>
            <div className="value3">{data?.agency?.nickname}</div>
          </div>
          <div className="container3-2">
            <div className="label3">예금주</div>
            <div className="value3">{data?.agency?.account_holder_name}</div>
          </div>
          <div className="container3-2">
            <div className="label3">계좌번호</div>
            <div className="value3">{data?.agency?.account_number}</div>
          </div>
          <div className="container3-2">
            <div className="label3">은행명</div>
            <div className="value3">{data?.agency?.withdrawal_bank}</div>
          </div>
          <div className="container3-2">
            <div className="label3">주민번호</div>
            <div className="value3">
              {data?.agency?.resident_registration_number}
            </div>
          </div>
        </div>
      </Stack>
      <Stack className="container3">
        <div className="title3">본인 인증 정보</div>
        <div className="container3-1">
          <div className="container3-2">
            <div className="label3">본인 인증</div>
            <div className="value3">
              {dataByID?.nice_infos[0]?.id ? "true" : "false"}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">본인 인증 방식</div>
            <div className="value3">
              {dataByID?.nice_infos[0]?.nice_auth_type}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">인증 날짜</div>
            <div className="value3">
              {convertTimeZone(
                +dataByID?.nice_infos[0]?.nice_verify_at_unix_timestamp
              )}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">인증</div>
            <div className="value3">{dataByID?.nice_infos[0]?.nice_name}</div>
          </div>
          <div className="container3-2">
            <div className="label3">성별</div>
            <div className="value3">{dataByID?.nice_infos[0]?.nice_gender}</div>
          </div>
          <div className="container3-2">
            <div className="label3">생년월일</div>
            <div className="value3">
              {convertNiceToDate(dataByID?.nice_infos[0]?.nice_birthdate)}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">본인 인증 방식</div>
            <div className="value3">
              {dataByID?.nice_infos[0]?.nice_mobile_no}
            </div>
          </div>
          <div className="container3-2">
            <div className="label3">통신사</div>
            <div className="value3">{dataByID?.nice_infos[0]?.nice_di}</div>
          </div>
        </div>
      </Stack>
    </Stack>
  );
}

export default DetailInfo;
