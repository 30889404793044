import React from "react";
import "./style.css";
import Modal from "@mui/material/Modal";
import ReactLoading from "react-loading";

const Loading = () => {
	return (
		<div className="loading">
			<ReactLoading type="bars" color="#FF466B" height={40} width={40} />
		</div>
	);
};

export default Loading;
