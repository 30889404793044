import Swal from "sweetalert2";

export function showError(text, icon, title) {
  // console.log("showError -> showError")
  return Swal.fire({
    icon: icon ? icon : "error",
    title: title ? title : "Error...",
    text: text,
    showConfirmButton: false,
    timer: 2000,
  });
}
export function showSuccess(text, icon, title) {
  //   console.log("showError -> showError");
  return Swal.fire({
    icon: icon ? icon : "success",
    title: title ? title : "Success",
    text: text,
    showConfirmButton: false,
    timer: 1500,
  });
}
