import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import { Field } from "formik";
import { InputField, SelectField, DataRangePickerField } from "../Fields";
import { headerFilter } from "src/util/data";

import FilterInputWrapper from "../FilterInputWrapper";

import "../style.css";

const ManagepostCeleb = () => {
  const { over_19, statusAccount, seacrhTime, memberInfoSelect } =
    headerFilter.settingDefaultMess;
  const [DateRange, setDateRange] = useState([]);

  return (
    <FormGroup>
      <Grid container spacing={2}>
        <Grid item xl={7}>
          <Grid container spacing={2}>
            <Grid item xl={12}>
              {/* time */}
              <FilterInputWrapper label="기간 설정">
                <Field
                  name="search_time"
                  component={SelectField}
                  data={seacrhTime}
                />
                <div style={{ marginRight: "7px" }}></div>
                <Field
                  name="time__data"
                  component={DataRangePickerField}
                  DateRange={DateRange}
                  setDateRange={setDateRange}
                />
              </FilterInputWrapper>
            </Grid>
            <Grid item xl={12}>
              {/* gender */}
              <FilterInputWrapper label="회원상태">
                <Field
                  name="status_account"
                  component={SelectField}
                  data={statusAccount}
                />
              </FilterInputWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xl={5}>
          <Grid container spacing={2}>
            <Grid item xl={12}>
              {/* member info */}
              <FilterInputWrapper label="회원정보">
                <Grid item xl={4} className="mr-2">
                  <Field
                    name="memberInfoSelect"
                    component={SelectField}
                    data={memberInfoSelect}
                  />
                </Grid>
                <Grid item xl={8}>
                  <Field
                    name="memberInfoInput"
                    component={InputField}
                    placeholder="입력해주세요"
                    className="inputFilter"
                  />
                </Grid>
              </FilterInputWrapper>
            </Grid>
            <Grid item xl={12}>
              {/* state */}
              <FilterInputWrapper label="성인인증">
                <Field
                  name="has_adult_content"
                  component={SelectField}
                  data={over_19}
                />
              </FilterInputWrapper>
              {/* agency code */}
              {/* <FilterInputWrapper label="에이전시 코드">
						<Field
							name="memberInfoInput"
							component={InputField}
							placeholder="최소스타"
							className="inputFilter"
						/>
					</FilterInputWrapper> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default ManagepostCeleb;
