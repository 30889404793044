import { Button, Stack } from "@mui/material";
import DataTable from "./DataTable";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import GetApi from "src/api/ListUserApi";
import { convertTextToKo, convertTimeZone } from "src/util/convert";
import SelectCustomInput from "./Fields/SelectCustomInput";
import { headerFilter } from "src/util/data";
import FieldCustom from "./Fields/FieldCustom";
import InputDateRangerCustome from "./Fields/InputDateRangerCustome";
import { filterListFollow } from "src/util/setFilter";
import InputCustom from "./Fields/InputCustom";
const DetailFollow = ({ id }) => {
  const { memeberInfo, status, genderArr, seacrhTime } =
    headerFilter.ListFollowUserProfile;
  const API = GetApi;
  const { register, handleSubmit, setValue } = useForm();
  const [item, setItem] = useState([]);

  const columns = [
    {
      field: "id",
      hide: true,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "no",
      headerName: "No",
      headerAlign: "center",

      sortable: false,
    },
    {
      field: "user_id",
      headerName: "회원아이디",
      flex: 1,
      headerAlign: "center",
      sortable: false,
    },
    {
      field: "user_nickname",
      headerName: "회원닉네임",
      flex: 1,

      headerAlign: "center",
      sortable: false,
    },
    {
      field: "user_gender",
      headerName: "성별",
      headerAlign: "center",
      flex: 1,
      renderCell: (params) => {
        return <>{convertTextToKo(params?.row?.user_gender)}</>;
      },
      sortable: false,
    },
    {
      field: "create_at",
      headerName: "시작일",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
    {
      field: "update_at",
      headerName: "처리날짜",
      flex: 1,

      headerAlign: "center",
      sortable: false,
    },

    {
      field: "status",
      headerName: "상태",
      headerAlign: "center",
      flex: 1,

      sortable: false,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState({
    followed_id: id,
  });

  const [dataDetail, setDataDetail] = useState();
  const getDetailUserFollow = async () => {
    let arr = [];

    try {
      const result = await API.getUserFollow(
        JSON.stringify({ ...filter }),
        currentPage,
        limit
      );
      setTotal(result?.results?.objects?.count);
      setPage(Math.max(Math.ceil(result.results.objects.count / limit), 1));
      result?.results?.objects?.rows.map((val, idx) => {
        return arr.push({
          id: val?.id,
          no: idx + (currentPage - 1) * limit + 1,
          user_id: val?.user?.identified_id,
          user_nickname: val?.user?.nickname,
          user_gender: val?.user?.sex,
          create_at: convertTimeZone(+val?.created_at_unix_timestamp),
          status: val?.status ? "FOLLOW" : "UNFOLLOW",
          update_at: val?.status
            ? "-"
            : convertTimeZone(+val?.updated_at_unix_timestamp),
        });
      });
      setDataDetail(result);
    } catch (error) {
      throw error;
    } finally {
      setItem(arr);
    }
  };
  const handleChangePage = (_, value) => {
    setCurrentPage(value);
  };
  const handleSetLimit = (value) => {
    setLimit(value);
    setCurrentPage(1);
  };
  useEffect(() => {
    getDetailUserFollow();
  }, [currentPage, limit, filter]);
  const onSubmit = (data) => {
    console.log(data);
    const filter = filterListFollow(data, id);
    setFilter(filter);
    setCurrentPage(1);
  };
  return (
    <>
      <Stack spacing={2}>
        <div>
          <div
            style={{
              background: "#F6F6F6 0% 0% no-repeat padding-box",
              border: "1px solid #E6ECF3",
              height: "50px",
              padding: "13px 22px",
            }}
          >
            <p>결제 정보</p>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formContainer">
              <div className="formContainer2">
                <FieldCustom label="회원정보">
                  <SelectCustomInput
                    setValue={setValue}
                    name="senderInfo"
                    width="20%"
                    data={memeberInfo}
                    register={register("senderInfo")}
                  />
                  <InputCustom
                    width="100%"
                    register={register("senderInput")}
                    placeholder="입력해주세요"
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="상태">
                  <SelectCustomInput
                    setValue={setValue}
                    name="status"
                    data={status}
                    register={register("status")}
                  />
                </FieldCustom>
              </div>
              <div className="formContainer2">
                <FieldCustom label="성별">
                  <SelectCustomInput
                    setValue={setValue}
                    name="sex"
                    data={genderArr}
                    register={register("sex")}
                  />
                </FieldCustom>
              </div>

              <div className="formContainer2">
                <FieldCustom label="기간검색">
                  <SelectCustomInput
                    setValue={setValue}
                    name="dateInput"
                    width="20%"
                    data={seacrhTime}
                    register={register("dateInput")}
                  />
                  <InputDateRangerCustome
                    registerStartDate={"dateStart"}
                    registerEndDate={"dateEnd"}
                    setValue={setValue}
                  />
                </FieldCustom>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  background: "black",
                  padding: "6px 16px",
                  width: "222px",
                  height: "56px",
                  borderRadius: "10px",
                }}
                type="submit"
              >
                검색
              </Button>
            </div>
          </form>
          <DataTable
            columns={columns}
            total={total}
            items={item}
            page={page}
            currentPage={currentPage}
            changePage={handleChangePage}
            fileName="history"
            showLimit={false}
            setLimit={handleSetLimit}
            showLimitV2={true}
            limit={limit}
          />
        </div>
      </Stack>
    </>
  );
};

export default DetailFollow;
