import React from "react";

import "./style.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import VideoPlayer from "./VideoPlayer";

import { getTypeUrl } from "src/util/Utils";
import { Swiper, SwiperSlide } from "swiper/react";

import { Navigation } from "swiper";

import { Pagination } from "swiper";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

import { imageTypes } from "src/util/Utils";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  maxHeight: "100vh",
  bgcolor: "background.paper",
  boxShadow: 24,
};
export const CardModal = ({
  open,
  handleClose,
  user,
  typeCard,
  item,
  content,
}) => {
  console.log(item);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            background: "#232323",
            height: "40px",
            padding: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p style={{ color: "white" }}>{user?.nickname || "닉네임"}</p>
          <CloseIcon onClick={handleClose} />
        </div>
        <div className="card__img" style={{ height: "100%" }}>
          {typeCard === "IMAGE" ? (
            item?.photos?.length === 1 ? (
              <img
                src={item?.photos[0]}
                width="100%"
                alt=""
                style={{ objectFit: "cover" }}
              />
            ) : (
              <Swiper
                modules={[Pagination, Navigation]}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                className="mySwiper"
              >
                {item?.photos?.map((item) => (
                  <SwiperSlide>
                    {imageTypes.includes(getTypeUrl(item)) ? (
                      <img
                        src={item}
                        width="100%"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    ) : (
                      <VideoPlayer link={item} />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            )
          ) : item?.medias?.length === 1 ? (
            <>
              {imageTypes.includes(getTypeUrl(item?.medias[0])) ? (
                <img
                  src={item?.medias[0]}
                  width="100%"
                  height={"auto"}
                  alt=""
                  style={{ objectFit: "cover" }}
                />
              ) : (
                <VideoPlayer link={item?.medias[0]} />
              )}
            </>
          ) : (
            <Swiper
              modules={[Pagination, Navigation]}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              className="mySwiper"
              style={{ height: "100%" }}
            >
              {item?.medias?.map((item) => (
                <SwiperSlide>
                  {imageTypes.includes(getTypeUrl(item)) ? (
                    <img
                      src={item}
                      width="100%"
                      height={"auto"}
                      alt=""
                      style={{ objectFit: "cover" }}
                    />
                  ) : (
                    <VideoPlayer link={item} />
                  )}
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        {content && typeCard !== "IMAGE" && (
          <div
            style={{
              background: "#FFFF",
              padding: "10px",
              borderTop: "1px solid",
            }}
          >
            {content}
          </div>
        )}
      </Box>
    </Modal>
  );
};
