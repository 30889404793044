import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "src/SLice/Employee";
import { useHistory } from "react-router-dom";
import TheSidebarDefault from "./TheSidebarDefault";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import TabHeader from "src/Layout/TabHeader";

import { CContainer } from "@coreui/react";
import MultiLanguage from "src/components/MultiLanguage";
import i18n from "src/translation/i18n";
import moment from "moment/moment";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
// import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import useWindowSize from "src/hooks/useWindowSize";

const drawerWidth = 385;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "transparent",
  boxShadow: "none",
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const TheLayout = ({ children }, props) => {
  const size = useWindowSize();
  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setlanguage] = useState(
    localStorage.getItem("i18nextLng") || "kr"
  );

  const dispatch = useDispatch();
  const history = useHistory();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    dispatch(logout());
    history.push("/login");
  };
  const handleChangeLanguage = (e) => {
    localStorage.setItem("i18nextLng", e.target.value);
    setlanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        {size.width < 720 && (
          <AppBar
            position="fixed"
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
            }}
          >
            <Toolbar>
              <Box
                width={"100%"}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  sx={{ mr: 2, display: { sm: "none" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div"></Typography>
              </Box>
            </Toolbar>
          </AppBar>
        )}

        <TheSidebarDefault
          container={container}
          isMobile={{ mobileOpen, setMobileOpen }}
          handleDrawerToggle={handleDrawerToggle}
        />

        <Box
          component="main"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            backgroundColor: "#f8f9fb;",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f8f9fb;",
              p: { lg: `50px`, xs: "50px 25px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: "22px",
              }}
            >
              <Typography
                sx={{ font: "normal normal bold 24px/46px Pretendard" }}
              >
                에이전시 어드민
              </Typography>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* <MultiLanguage
                  language={{ value: language, setValue: setlanguage }}
                  handleChangeLanguage={handleChangeLanguage}
                /> */}
                <IconButton
                  onClick={handleClick}
                  size="large"
                  // aria-controls={open ? "account-menu" : undefined}
                  // aria-haspopup="true"
                  // aria-expanded={open ? "true" : undefined}
                >
                  <Settings />
                </IconButton>
              </Box>
            </Box>
            <TabHeader />
            {children}
          </Box>
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </>

    // <div className="c-app ">
    //    <div className="c-wrapper">
    //     <div className="c-body">
    //       <div
    //         style={{
    //           display: "flex",
    //           justifyContent: "flex-end",
    //           marginTop: "20px",
    //         }}>
    //         {" "}
    //         <MultiLanguage
    //           language={{ value: language, setValue: setlanguage }}
    //           handleChangeLanguage={handleChangeLanguage}
    //         />
    //         <IconButton
    //           onClick={handleClick}
    //           size="large"
    //           sx={{ mr: 2 }}
    //           aria-controls={open ? "account-menu" : undefined}
    //           aria-haspopup="true"
    //           aria-expanded={open ? "true" : undefined}>
    //           <Settings />
    //         </IconButton>
    //       </div>

    //       <main className="c-main">
    //         <CContainer fluid>
    //           <h3 style={{ marginBottom: "40px", color: "black" }}>
    //             에이전시 어드민
    //           </h3>

    //           <TabHeader />
    //           {children}
    //         </CContainer>
    //       </main>
    //     </div>
    //   </div>
    //   <Menu
    //     anchorEl={anchorEl}
    //     id="account-menu"
    //     open={open}
    //     onClose={handleClose}
    //     onClick={handleClose}
    //     PaperProps={{
    //       elevation: 0,
    //       sx: {
    //         overflow: "visible",
    //         filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    //         mt: 1.5,
    //         "& .MuiAvatar-root": {
    //           width: 32,
    //           height: 32,
    //           ml: -0.5,
    //           mr: 1,
    //         },
    //         "&:before": {
    //           content: '""',
    //           display: "block",
    //           position: "absolute",
    //           top: 0,
    //           right: 14,
    //           width: 10,
    //           height: 10,
    //           bgcolor: "background.paper",
    //           transform: "translateY(-50%) rotate(45deg)",
    //           zIndex: 0,
    //         },
    //       },
    //     }}
    //     transformOrigin={{ horizontal: "right", vertical: "top" }}
    //     anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
    //     <MenuItem onClick={handleLogout}>
    //       <ListItemIcon>
    //         <Logout fontSize="small" />
    //       </ListItemIcon>
    //       Logout
    //     </MenuItem>
    //   </Menu>
    // </div>
  );
};

export default TheLayout;
