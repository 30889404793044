import React, { useEffect, useState } from "react";
import ko from "date-fns/locale/ko";
import DatePicker from "react-datepicker";
import "./style.scss";
function InputDateRangerCustome({
  registerStartDate,
  registerEndDate,
  placeholder,
  setValue,
  style,
}) {
  const [startDate, setStartDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  useEffect(() => {
    setValue(registerStartDate, startDate);
    setValue(registerEndDate, lastDate);
    if (startDate > lastDate) setLastDate(startDate);
  }, [startDate, lastDate]);
  return (
    <div className="date-picker-container">
      <div style={style}>
        <DatePicker
          locale={ko}
          {...registerStartDate}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          placeholderText={placeholder ? placeholder : "YYYY/MM/DD"}
          showTimeSelect
          timeCaption="시간"
          dateFormat="yyyy/MM/dd HH:mm"
        />
      </div>
      <div style={{ margin: "0 4px", display: "flex", alignItems: "center" }}>
        -
      </div>
      <div style={style}>
        <DatePicker
          {...registerEndDate}
          selected={lastDate}
          onChange={(date) => setLastDate(date)}
          locale={ko}
          minDate={startDate}
          placeholderText={placeholder ? placeholder : "YYYY/MM/DD"}
          showTimeSelect
          timeCaption="시간"
          dateFormat="yyyy/MM/dd HH:mm"
        />
      </div>
    </div>
  );
}

export default InputDateRangerCustome;
