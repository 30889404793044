import React from "react";

function FieldCustom({
  children,
  label,
  alignItems = "center",
  minWidth = 200,
}) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems,
        height: "100%",
      }}
    >
      <div style={{ minWidth, margin: 5, fontWeight: 700 }}>{label}</div>
      {children}
    </div>
  );
}

export default FieldCustom;
