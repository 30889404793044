import React, { useEffect, useState } from "react";
import "./style.scss";
import { MenuItem, Select } from "@mui/material";
import styled from "@emotion/styled";
const SelectCustomInput = ({
  register,
  setValue,
  data,
  width,
  name,
  handleSetDisplayData,
  ...passProps
}) => {
  const SelectInput = styled(Select)(({ theme }) => ({
    borderRadius: "10px",
    background: "#FAFAFA",
    border: "1px solid #E1E1E1",
    width: "100%",
    height: "50px",
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
  }));
  const [currentValue, setCurrentValue] = useState(data[0]?.value);
  const handleOnChange = (value) => {
    console.log(value);
    setCurrentValue(value);
    setValue(name, value);
    if (handleSetDisplayData) {
      handleSetDisplayData(value);
    }
  };
  useEffect(() => {
    setCurrentValue(data[0]?.value);
  }, [JSON.stringify(data)]);
  return (
    // <select
    //   name="cars"
    //   id="cars"
    //   {...register}
    //   className="custom-select"
    //   style={{ width, background: "#f8f9fb" }}
    //   {...passProps}
    // >
    //   {data.map((item) => (
    //     <option value={item.value} key={item.value}>
    //       {item.label}
    //     </option>
    //   ))}
    // </select>,mẩ
    <SelectInput
      {...register}
      {...passProps}
      style={{ width, background: "#f8f9fb", margin: "5px" }}
      value={currentValue}
      onChange={(e) => handleOnChange(e.target.value)}
    >
      {data.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SelectInput>
  );
};

export default SelectCustomInput;
