import LoadingComponent from "../components/Loading";
import React, { createContext, useState } from "react";

const LoadingContext = createContext();

function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false);

  const value = {
    loading: loading,
    showLoading: () => setLoading(true),
    hideLoading: () =>
      setTimeout(() => {
        setLoading(false);
      }, 500),
  };

  return (
    <LoadingContext.Provider value={value}>
      <>
        {loading && <LoadingComponent />}
        {children}
      </>
    </LoadingContext.Provider>
  );
}

export { LoadingContext, LoadingProvider };
