import "./style.css";
import React from "react";
import { Grid, Typography } from "@mui/material";

const FilterInputWrapper = ({ label, children }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} xl={2}>
        <Typography
          sx={{
            font: "normal normal 600 18px/46px Pretendard",
          }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} xl={10} sx={{ display: "flex" }}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FilterInputWrapper;
