import React from "react";
import FieldCustom from "../Fields/FieldCustom";
import SelectCustomInput from "../Fields/SelectCustomInput";
import InputCustom from "../Fields/InputCustom";
import { headerFilter } from "src/util/data";

function FilterListCelebDonate({ register, setValue }) {
  const { memberInfoSelect, genderArr } = headerFilter.listCelebDonate;
  return (
    <div>
      <div className="formContainer" style={{ background: "white" }}>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="회원정보">
            <SelectCustomInput
              setValue={setValue}
              width="50%"
              data={memberInfoSelect}
              register={register("senderInfo")}
              name="senderInfo"
            />
            <InputCustom
              width="100%"
              register={register("senderInput")}
              placeholder="입력해주세요"
            />
          </FieldCustom>
        </div>
        <div className="formContainer2">
          <FieldCustom minWidth={120} label="성별">
            <SelectCustomInput
              setValue={setValue}
              data={genderArr}
              register={register("sex")}
              name="sex"
            />
          </FieldCustom>
        </div>
      </div>
    </div>
  );
}

export default FilterListCelebDonate;
