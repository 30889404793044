import axiosClient from "./axiosClient";
import { getToken } from "src/util/Utils";

const token = JSON.parse(localStorage.getItem("token"));
const HOSTNAME = process.env.REACT_APP_API;
const fields = 'fields=["$all", {“user“: ["$all"]}]';
const table = "withdraw";

const Api = {
  getAll: (page, limit, filter, filterWithdraw) => {
    const url = `${HOSTNAME}/${table}?fields=["$all", {"user": ["$all", {"$filter": ${filter}}]}]&filter=${filterWithdraw}&page=${page}&limit=${limit}&order=[["created_at","desc"]]`;
    return axiosClient.get(url);
  },
  getAllById: (page, limit, filter) => {
    const url = `${HOSTNAME}/${table}/getListCustom?fields=["$all", {"user": ["$all"]}]&filter=${filter}&page=${page}&limit=${limit}&order=[["created_at_unix_timestamp","desc"]]`;
    return axiosClient.get(url);
  },
  deny: (arr) => {
    const urlDeny = `${HOSTNAME}/agency_withdraw/deny_request`;
    return axiosClient.post(urlDeny, arr);
  },

  accept: (arr) => {
    const urlAccept = `${HOSTNAME}/agency_withdraw`;
    return axiosClient.post(urlAccept, arr);
  },
  acceptCeleb: (arr) => {
    const urlAccept = `${HOSTNAME}/withdraw/agency_request_withdraw_for_user`;
    return axiosClient.post(urlAccept, arr);
  },

  // update: (body, id) => {
  //   const url = `${HOSTNAME}/${table}/${id}`;
  //   console.log("url...", url);
  //   return axiosClient.put(url, body, {
  //     headers: {
  //       Authorization: `Bearer ${getToken()}`,
  //     },
  //   });
  // },
};

export default Api;
