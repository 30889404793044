import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

const SelectInput = styled(Select)(({ theme }) => ({
  borderRadius: "10px",
  background: "#FAFAFA",
  border: "1px solid #E1E1E1",
  width: "100%",
  height: "50px",
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
}));

const SelectField = ({ field, form, data }) => {
  return (
    <SelectInput
      value={field.value}
      onChange={(e) => form.setFieldValue(field.name, e.target.value)}
      defaultValue={data[0]?.value}>
      {data.map((item, index) => (
        <MenuItem key={index} value={item.value}>
          {item.label}
        </MenuItem>
      ))}
    </SelectInput>
  );
};

export default SelectField;
