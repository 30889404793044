import moment from "moment";

export const filterPayment = (data, id) => {
  let filter = { user_id: id };
  if (data.state !== "ALL") {
    filter = { ...filter, state: data.state };
  }
  if (data.type !== "ALL") {
    filter = { ...filter, type: data.type };
  }
  if (data.nickname) {
    filter = {
      ...filter,
      "$user.nickname$": { $iLike: `%25${data.nickname}%25` },
    };
  }
  if (data.transaction_number) {
    filter = { ...filter, transaction_number: data.transaction_number };
  }
  console.log();
  if (data.dateStart && data.dateEnd) {
    filter = {
      ...filter,
      created_at_unix_timestamp: {
        $between: [
          moment(data.dateStart).valueOf(),
          moment(data.dateEnd).valueOf(),
        ],
      },
    };
  }
  return filter;
};
export const filterUsageStar = (data, id) => {
  let filter = { user_id: id };
  if (data.receiver_id === "OUT") {
    filter = { ...filter, receiver_id: { $ne: id } };
  } else if (data.receiver_id === "IN") {
    filter = { ...filter, receiver_id: id };
  }
  if (data.type !== "ALL") {
    filter = { ...filter, type: data.type };
  }
  if (data.dateStart && data.dateEnd) {
    filter = {
      ...filter,
      created_at_unix_timestamp: {
        $between: [
          moment(data.dateStart).valueOf(),
          moment(data.dateEnd).valueOf(),
        ],
      },
    };
  }
  return filter;
};
export const filterListCoin = (data, id) => {
  let filter = { receiver_id: id };
  if (data.senderInput) {
    filter = {
      ...filter,
      [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
    };
  }
  if (data.type !== "ALL") {
    filter = { ...filter, type: data.type };
  }
  if (data.dateStart && data.dateEnd) {
    filter = {
      ...filter,
      created_at_unix_timestamp: {
        $between: [
          moment(data.dateStart).valueOf(),
          moment(data.dateEnd).valueOf(),
        ],
      },
    };
  }
  console.log("filter", filter);
  return filter;
};
export const filterBuyFeed = (data, id) => {
  let filter = { user_id: id, approve_state: "APPROVED" };
  let filter2 = null;
  let filter3 = null;
  if (data.senderInput) {
    filter = {
      ...filter,
      sender: {
        [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
      },
    };
  }
  if (data.period_type !== "ALL") {
    filter = {
      ...filter,
      secret_album: {
        period_type: data.period_type,
      },
    };
  }
  if (data.type !== "ALL") {
    if (data.type !== "FREE") {
      filter = { ...filter, type: { $ne: "FREE" } };
    } else {
      filter = { ...filter, type: "FREE" };
    }
  }
  // if(data.senderInput){
  //   filter={...filter, [data.senderInfo]:{"$iLike":""}}
  // }
  if (data.dateStart && data.dateEnd) {
    if (data.dateInput !== "ALL") {
      filter = {
        ...filter,
        [data.dateInput]: {
          $between: [
            moment(data.dateStart).valueOf(),
            moment(data.dateEnd).valueOf(),
          ],
        },
      };
    } else {
      filter = {
        ...filter,
        $or: [
          {
            created_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
          {
            expose_time: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
        ],
      };
    }
  }
  if (data.type_media !== "ALL") {
    filter = { ...filter, type_media: data.type_media };
  }
  console.log("filter", filter);
  const totalFilter = filter;
  return totalFilter;
};
export const filterListFollow = (data, id) => {
  let filter = { followed_id: id };
  if (data.senderInput) {
    filter = {
      ...filter,
      [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
    };
  }
  if (data.status !== "ALL") {
    filter = { ...filter, status: data.status };
  }
  if (data.sex !== "ALL") {
    filter = { ...filter, "$followed.sex$": data.sex };
  }
  if (data.dateStart && data.dateEnd) {
    if (data.dateInput !== "ALL") {
      if (data.dateInput === "updated_at_unix_timestamp") {
        console.log("đã zô");
        filter = {
          ...filter,
          [data.dateInput]: {
            $between: [
              moment(data.dateStart).valueOf(),
              moment(data.dateEnd).valueOf(),
            ],
          },
          status: false,
        };
      } else
        filter = {
          ...filter,
          [data.dateInput]: {
            $between: [
              moment(data.dateStart).valueOf(),
              moment(data.dateEnd).valueOf(),
            ],
          },
        };
    } else {
      filter = {
        ...filter,
        $or: [
          {
            created_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
          {
            updated_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
        ],
      };
    }
  }
  console.log("filter", filter);
  return filter;
};

export const filterListSubcription = (data, id) => {
  let filter = { subscribed_id: id };
  if (data.senderInput) {
    filter = {
      ...filter,
      [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
    };
  }
  if (data.type !== "ALL") {
    filter = { ...filter, type: data.type };
  }
  if (data.sex !== "ALL") {
    filter = { ...filter, "$user.sex$": data.sex };
  }
  if (data.type_subscriber !== "ALL") {
    filter = { ...filter, "$pack_sub.type$": data.type_subscriber };
  }
  if (data.dateStart && data.dateEnd) {
    if (data.dateInput !== "ALL") {
      filter = {
        ...filter,
        [data.dateInput]: {
          $between: [
            moment(data.dateStart).valueOf(),
            moment(data.dateEnd).valueOf(),
          ],
        },
      };
    } else {
      filter = {
        ...filter,
        $or: [
          {
            created_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
          {
            expire_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
        ],
      };
    }
  }
  console.log("filter", filter);
  return filter;
};

export const filterCelebWithDraw = (data, id) => {
  console.log("filterCelebWithDraw", data);
  let filter = { "$user.agency_id$": id };
  // filter = {};
  if (data.senderInput) {
    if (data?.senderInfo === "$user.agency_member_no$") {
      filter = {
        ...filter,
        [data.senderInfo]: Number(data.senderInput),
      };
    } else {
      filter = {
        ...filter,
        [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
      };
    }
  }
  if (data.status_account && data.status_account !== "ALL") {
    filter = { ...filter, "$user.status_account$": data.status_account };
  }
  if (data.sex !== "ALL") {
    filter = { ...filter, "$user.sex$": data.sex };
  }
  if (data.state !== "ALL") {
    filter = { ...filter, state: data.state };
  }
  if (data.dateStart && data.dateEnd) {
    if (data.dateInput !== "ALL") {
      filter = {
        ...filter,
        [data.dateInput]: {
          $between: [
            moment(data.dateStart).valueOf(),
            moment(data.dateEnd).valueOf(),
          ],
        },
      };
    } else {
      filter = {
        ...filter,
        $or: [
          {
            created_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
          {
            updated_at_unix_timestamp: {
              $between: [
                moment(data.dateStart).valueOf(),
                moment(data.dateEnd).valueOf(),
              ],
            },
          },
        ],
      };
    }
  }
  console.log("datafilter", filter);
  return filter;
};

export const filterListCelebDonate = (data, id) => {
  let filter = { agency_id: id };
  console.log(
    "datafilterListCelebDonate",
    data,
    "filterListCelebDonate id",
    id
  );
  if (data.senderInput) {
    filter = {
      ...filter,
      [data.senderInfo]: { $iLike: `%25${data.senderInput}%25` },
    };
  }
  if (data.sex !== "ALL") {
    filter = { ...filter, sex: data.sex };
  }
  return filter;
};
