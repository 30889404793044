import React, { useState } from "react";
import "./style.css";
import Button from "@mui/material/Button";
import { Field, Form, Formik } from "formik";
import { valueFilter } from "src/util/Utils";
import { keyHeaderFilter } from "src/util/data";
import { Box } from "@mui/material";

const TheHeaderDefault = ({
  title,
  Component,
  setFilter,
  typeHeadeFilter,
  initialValues,
}) => {
  const [arr, setarr] = useState({});
  const { LIST_USER, LIST_COIN, SETLETMENT } = keyHeaderFilter;

  const handleSubmit = (values, { resetForm }) => {
    console.log(
      "🚀 ~ file: TheHeaderDefault.js:14 ~ handleSubmit ~ values",
      values
    );
    let exchangeValue;
    const { listUser, listCoin, listSetletment } = valueFilter;
    switch (typeHeadeFilter) {
      case LIST_USER: {
        exchangeValue = listUser(values);
        setFilter(exchangeValue);
        break;
      }
      case LIST_COIN: {
        exchangeValue = listCoin(values);
        setFilter(exchangeValue);
        break;
      }
      case SETLETMENT: {
        exchangeValue = listSetletment(values);
        setFilter(exchangeValue);
        break;
      }

      default:
        break;
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues || arr}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const {
          values,
          errors,
          touched,
          setFieldValue,
          isSubmitting,
          resetForm,
        } = formikProps;
        return (
          <Form>
            <div className="headerContainer">
              <Box
                sx={{
                  backgroundColor: "#fff",
                  border: "1px solid #e6ecf3",
                  p: { xs: "10px", md: "22px 67px" },
                }}
              >
                <Component handleSubmit={handleSubmit} />
              </Box>
              <div className="buttonContainer">
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#3B3B3B",
                    color: "white",
                    width: { xs: "100%", md: 221 },
                    height: 52,
                    borderRadius: "10px",
                    fontSize: "18px",
                  }}
                  type="submit"
                >
                  조회
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TheHeaderDefault;
