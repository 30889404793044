import Grid from "@mui/material/Grid";
import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import { Field } from "formik";
import {
  InputField,
  SelectField,
  DataRangePickerField,
  RadioField,
  CheckBoxField,
} from "../Fields";
import { headerFilter } from "src/util/data";
import FilterInputWrapper from "../FilterInputWrapper";

import "../style.css";

const ManagepostCelebCoin = () => {
  const { memeberInfo, statusAccount, seacrhTime, memberInfoSelect, reason } =
    headerFilter.listCoin;
  const [DateRange, setDateRange] = useState([]);

  return (
    <FormGroup>
      <Grid container spacing={2}>
        <Grid item md={7}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {/* time */}
              <FilterInputWrapper label="기간 설정">
                <Field
                  name="time__data"
                  component={DataRangePickerField}
                  DateRange={DateRange}
                  setDateRange={setDateRange}
                />
              </FilterInputWrapper>
            </Grid>
            <Grid item sm={12}>
              {/* gender */}
              <FilterInputWrapper label="셀럽 상태">
                <Field
                  name="status_account"
                  component={SelectField}
                  data={statusAccount}
                />
              </FilterInputWrapper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              {/* member info */}
              <FilterInputWrapper label="셀럽 정보">
                <Grid item xs={4} className="mr-2">
                  <Field
                    name="memberInfoSelect"
                    component={SelectField}
                    data={memberInfoSelect}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Field
                    name="memberInfoInput"
                    component={InputField}
                    placeholder="입력해주세요 "
                    className="inputFilter"
                  />
                </Grid>
              </FilterInputWrapper>
            </Grid>
            <Grid item sm={12}>
              <FilterInputWrapper label="사유">
                <Field name="type" component={SelectField} data={reason} />
              </FilterInputWrapper>
            </Grid>
            {/* agency code */}
            {/* <FilterInputWrapper label="에이전시 코드">
						<Field
							name="memberInfoInput"
							component={InputField}
							placeholder="최소스타"
							className="inputFilter"
						/>
					</FilterInputWrapper> */}
          </Grid>
        </Grid>
      </Grid>
    </FormGroup>
  );
};

export default ManagepostCelebCoin;
