import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./style.css";

const DateRangePicker = ({ DateRange, setDateRange, name, form, placeholder }) => {
	const onChange = (dates) => {
		const endDate = moment(dates[1])
			.set({ hour: 23, minute: 59, second: 59, millisecond: 59 })
			.valueOf();
		const newDate = [dates[0], endDate];
		form.setFieldValue(name, newDate);
		setDateRange(newDate);
	};

	const [open, setOpen] = useState(false);

	return (
		<>
			<input
				className="inputFilter"
				readOnly
				placeholder={placeholder && placeholder[0] ? placeholder[0] : "YYMMDD"}
				onClick={() => setOpen(!open)}
				value={DateRange[0] ? moment(DateRange[0]).format("YYYY/MM/DD") : ""}
			/>
			<div style={{ margin: "0 8px", display: "flex", alignItems: "center" }}>-</div>
			<input
				className="inputFilter"
				readOnly
				placeholder={placeholder && placeholder[1] ? placeholder[1] : "YYMMDD"}
				onClick={() => setOpen(!open)}
				value={DateRange[1] ? moment(DateRange[1]).format("YYYY/MM/DD") : ""}
			/>
			{open && (
				<div style={{ position: 'relative', left: '-55%' }}>
					<DatePicker
						selected={DateRange[0]}
						onChange={onChange}
						startDate={DateRange[0]}
						endDate={DateRange[1]}
						selectsRange
						onClickOutside={() => setOpen(false)}
						inline
					/>
				</div>
			)}
		</>
	);
};

export default DateRangePicker;
