import { createSlice } from "@reduxjs/toolkit";
const inintialSate =()=> {
    return {
    text : '',
    colFilter :'',
    statusFilter : '',
    }
}

const Query = createSlice({
    name : "Query",
    initialState : inintialSate(),
    reducers : {
       getColFilter : (state,actions)=>{
       state.colFilter = actions.payload
       },
       getText : (state,actions)=>{
        state.text = actions.payload
       },
       getStatusFilter : (state,actions)=>{
        state.statusFilter = actions.payload
       },
       resetFilter : (state)=>inintialSate()
       
    }
})

const {reducer , actions} = Query;
export const { getColFilter,getText, resetFilter,getStatusFilter} = actions;
export default reducer;