import React from "react";
import "./style.scss";
const InputCustom = ({ register, placeholder, width, ...passProps }) => {
  return (
    <>
      <input
        className="customScss"
        style={{ width, background: "#f8f9fb" }}
        placeholder={placeholder ? placeholder : ""}
        {...passProps}
        {...register}
      />
    </>
  );
};

export default InputCustom;
