import Swal from "sweetalert2";
import moment from "moment";
import { typeMedia, typePost, platFormname, userType, typePay } from "./data";
import { AsYouType } from "libphonenumber-js";
import { convertTimeZone } from "./convert";
export const getToken = () => JSON.parse(localStorage.getItem("token"));

export const toHHMMSS = (item) => {
  if (!item) {
    return "00:00:00";
  } else {
    var sec_num = parseInt(item, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  }
};
export function validateString(value) {
  let error;
  if (value.trim() === "") {
    error = ` this field  must have `;
  }
  return error;
}

export function validateUrlImg(value) {
  let error;
  if (!value) {
    error = ` this field  must have `;
  }
  return error;
}
export function validateNumber(value) {
  let error;
  if (isNaN(value)) {
    error = ` this field  must is a number `;
  }
  return error;
}

export function validateWebUrl(value) {
  let error;
  let url = isWebUrl(value);
  if (!url) {
    error = ` this field  must be start with http or https `;
  }
  return error;
}
export function isWebUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}
export const preventkeyBoard = (event, colFilter) => {
  if (colFilter === "phone") {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }
  return;
};

const handleDelete = async (id, API, setItems, items) => {
  const response = await API.delete(id, getToken());
  if (response.code === 200) {
    const itemFind = items.filter((item) => item.id !== id);
    setItems(itemFind);
  } else {
    return;
  }
};

export const deleteAction = (id, API, setItems, items) => {
  Swal.fire({
    title: "Are you sure?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, delete it",
    cancelButtonColor: "#d33",
    cancelButtonText: "No, keep it",
    customClass: "custom-swal",
  }).then((result) => {
    if (result.isConfirmed) {
      handleDelete(id, API, setItems, items);
      Swal.fire({
        icon: "success",
        title: "Deleted!",
        showConfirmButton: false,
        timer: 1500,
        customClass: "custom-swal",
      });
    }
  });
};

export const checkTypeMedia = (media) => {
  const type = media[0].split(".").slice(-1)[0];
  if (type === "png") return typeMedia.Image;
  else if (type === "mp4") return typeMedia.video;
  else return typeMedia.album;
};

export const checkGender = (gender) => {
  if (gender === "MALE") return "남";
  else return "여";
};

export const checkTypePost = (type) => {
  if (type === typePost.publicAll) return "모두공개";
  if (type === typePost.ONE_TIME) return "유료";
  if (type === typePost.SUBCCRIBE) return "구독";
};
export const checkTypeCoin = (type, end_time, start_time) => {
  // if (type === typeCoin.ATTENDANCE) return "출석체크";
  // if (type === typeCoin.BUY_POST) return "포스트를 사다";
  // if (type === typeCoin.SEND_GIFT) return "선물을 보내다";
  // if (type === typeCoin.BUY_SECRET_ALBUM) return "비밀앨범 구매";
  // if (type === typeCoin.BUY_SECRET_MESSAGE) return "비밀 메시지 구매";
  // if (type === typeCoin.VIDEO_CALL) return "영상채팅 수신자부담 수락";
  switch (type) {
    case "GIFT":
      return "선물";
    case "SEND_GIFT":
      return "스타선물 ";
    case "GIFT_FROM_VIDEO":
      return "영상통화중 선물";
    case "GIFT_FROM_CHAT":
      return "문자채팅중 선물";
    case "BUY_SECRET_ALBUM":
      return " 앨범구매 ";
    case "BUY_SECRET_MESSAGE":
      return "피드구매";
    case "BUY_POST":
      return "피드구매";
    case `VIDEO_CALL`:
      return `영상통화 (${convertDurationToTime(Math.floor((end_time - start_time) / 1000))})`;
    case "SUBSCRIPTION":
      return "구독 ";
    case "TEXT":
      return " 문자채팅 ";
    case "STICKER":
      return "이모티콘";
    case "IN_APP_PURCHASE":
      return "스타구매";
    case "PLUS_KIT":
      return `플러스킷 (${convertDurationToTime(Math.floor((end_time - start_time) / 1000))})`;
    default:
      return "기타";
  }
};
export const checkStatusPay = (type) => {
  if (type === typePay.PROCESS) return "신청";
  if (type === typePay.PENDING) return "PENDING";
  if (type === typePay.ACCEPT) return "완료";
  if (type === typePay.DENY) return "취소";
  if (type === typePay.AGENCY_PENDING) return "대행사 대기 중";
  // return type;
};
export const checkPlatForm = (platForm) => {
  const { instagram_link, other_link, twitter_link, youtube_link, tiktok_link } = platForm;
  if (instagram_link) return platFormname.INSTAGRAM;
  else if (other_link) return platFormname.OTHER;
  else if (twitter_link) return platFormname.TWITTER;
  else if (youtube_link) return platFormname.YOUTUBE;
  else if (tiktok_link) return platFormname.TIKTOK;
  else return null;
};
export const checkPlatFormLink = (platForm) => {
  const { instagram_link, other_link, twitter_link, youtube_link, tiktok_link } = platForm;
  if (instagram_link) return instagram_link;
  else if (other_link) return other_link;
  else if (twitter_link) return twitter_link;
  else if (youtube_link) return youtube_link;
  else if (tiktok_link) return tiktok_link;
  else return null;
};

export const checkUserType = (type) => (type === userType.NORMAL ? "#236DFF" : "#FD5257");
export const checkGenderColor = (type, option) => {
  switch (option) {
    case "COLOR":
      return type === "MALE" ? "#E0F1FF" : "#FFDBDB";
    case "TEXT":
      return type === "MALE" ? "M" : "F";
    default:
      break;
  }
};

export const valueFilter = {
  listUser: (data) => {
    console.log("🎃🎃🎃 ~ file: Utils.js:220 ~ listUser:", data);
    // console.log(data);
    let filterValue = {};
    if (data.search_time && data.time__data.length > 0) {
      filterValue = {
        ...filterValue,
        [data.search_time]: {
          $between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
        },
      };
    }
    if (data?.memberInfoInput && data?.memberInfoInput !== "") {
      console.log("vafo");

      if (data?.memberInfoSelect === "agency_member_no") {
        filterValue = {
          ...filterValue,
          [data.memberInfoSelect]: Number(data?.memberInfoInput),
        };
      } else {
        filterValue = {
          ...filterValue,
          [data.memberInfoSelect]: {
            $iLike: `%25${data?.memberInfoInput.trim()}%25`,
          },
        };
      }
    }
    if (data.status_account)
      filterValue = {
        ...filterValue,
        status_account: { $iLike: `%25${data?.status_account}%25` },
      };
    if (data.has_adult_content) {
      if (data.has_adult_content === "ALL") return filterValue;
      filterValue = {
        ...filterValue,
        has_adult_content: data.has_adult_content,
      };
    } else {
      filterValue = {
        ...filterValue,
        has_adult_content: data.has_adult_content,
      };
    }

    return filterValue;
  },
  listCoin: (data) => {
    console.log("🎃🎃🎃 ~ file: Utils.js:272 ~ listCoin:", data);
    // console.log(data);
    let filterValue = {};
    if (data.time__data.length > 0) {
      filterValue = {
        ...filterValue,
        created_at_unix_timestamp: {
          $between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
        },
      };
    }
    if (data.memberInfoInput)
      filterValue = {
        ...filterValue,
        [data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
      };
    if (data.status_account) {
      if (data.status_account !== "ALL")
        filterValue = {
          ...filterValue,
          "$receiver.status_account$": {
            $iLike: `%25${data?.status_account}%25`,
          },
        };
    }
    if (data.type) {
      if (data.type !== "ALL")
        filterValue = {
          ...filterValue,
          type: data?.type,
        };
    }
    console.log("kk", filterValue);
    return filterValue;
  },
  listSetletment: (data) => {
    console.log("🎃🎃🎃 ~ file: Utils.js:293 ~ listSetletment:", data);
    // console.log(data);
    let filterValue = {};
    if (data.search_time) {
      filterValue = {
        ...filterValue,
        [data.search_time]: {
          $between: [moment(data.time__data[0]).valueOf(), moment(data.time__data[1]).valueOf()],
        },
      };
    }
    if (data.memberInfoSelect)
      filterValue = {
        ...filterValue,
        [data.memberInfoSelect]: { $iLike: `%25${data?.memberInfoInput}%25` },
      };
    if (data.status_account)
      filterValue = {
        ...filterValue,
        status_account: { $iLike: `%25${data?.status_account}%25` },
      };
    if (data.register_status)
      filterValue = {
        ...filterValue,
        register_status: { $iLike: `%25${data?.register_status}%25` },
      };
    if (data.gender)
      filterValue = {
        ...filterValue,
        gender: { $iLike: `%25${data?.gender}%25` },
      };
    if (data.status_purchase)
      filterValue = {
        ...filterValue,
        status_purchase: { $iLike: `%25${data?.status_purchase}%25` },
      };

    return filterValue;
  },
};

export const convertPhoneNumber = (phone) => {
  if (!phone) {
    return "";
  }
  // Set default country code to US if no real country code is specified
  const defaultCountryCode = phone.substr(0, 1) !== "+" ? "US" : null;
  let formatted = new AsYouType(defaultCountryCode).input(phone);
  let countryCode = "";
  let withoutCountryCode = formatted;

  if (defaultCountryCode === "US") {
    countryCode = "+1";
    formatted = "+1 " + formatted;
  } else {
    const parts = formatted.split(" ");
    countryCode = parts.length > 1 ? parts.shift() : "";
    withoutCountryCode = parts.join("");
  }

  return `(${countryCode}) ${withoutCountryCode}`;
};

export const USersInConverSation = (listUSer) => {
  return listUSer.filter((user) => user.type === "MEMBER");
};
export const getAgeUser = (timestamp) => {
  // const birthday = moment().diff(moment(age).format("ll"), "years");
  // return birthday > 0 ? birthday : 1;
  const birthday = moment(timestamp);
  const today = moment();
  const age = today.diff(birthday, "years");
  return age;
};
// export const getAgeUser = () => {
//   const getAge = useCallback((timestamp) => {
//     const birthday = moment(timestamp);
//     const today = moment();
//     const age = today.diff(birthday, "years");
//     return age;
//   }, []);

//   return getAge;
// };

export const getTypeUrl = (url) => {
  console.log("🚀 ~ file: Utils.js:381 ~ getTypeUrl ~ url:", url);
  // return url ? url.slice(-3) : "";
  if (typeof url === "string") {
    const parts = url.split("."); // Tách chuỗi thành mảng các phần tử dựa trên dấu chấm
    // console.log("urrrrrl", parts[parts.length - 1]);
    return parts.length > 1 ? parts[parts.length - 1] : "";
  } // Trả về phần tử cuối cùng hoặc toàn bộ chuỗi nếu không có dấu chấm
  return url;
};

export const totalPayment = (arr) => {
  return arr?.reduce((total, item) => (total += item?.pack?.price), 0);
};

export const handleType = (typeMedia) => {
  if (typeMedia === "ALBUM") {
    return "앨범 "; //ALBUM
  } else if (typeMedia === "IMAGE") {
    return "사진"; //IMAGE
  } else if (typeMedia === "VIDEO") return "동영상"; //VIDEO
  else return "-";
};
export const checkPeriodType = (period_type) => {
  switch (period_type) {
    case "NO_LIMIT":
      return "무제한";

    case "ONE_DAY":
      return "하루";

    case "ONE_WEEK":
      return "일주일";

    case "TWO_WEEK":
      return "이주일";

    case "ONE_MONTH":
      return "한달";
    case "ENTER":
      return "직접입력";
    default:
      return "-";
  }
};

export const handleSetAllTitles = (data) => {
  const AllTitles = [];
  data?.map((item) => {
    const dataSet = {
      title: item.title || "-",
      label: item.title || "-",
      type: item.type || "-",
      answer: item.answer || "-",
      content: item.content || "-",
      value: item.id || "-",
      id: item.id || "-",
      created_at_unix_timestamp: convertTimeZone(Number(item.created_at_unix_timestamp)) || "-",
      user_identified_id: item.user?.identified_id || "-",
      user_nickname: item.user.nickname || "-",
      processed_at_unix_timestamp: convertTimeZone(Number(item.processed_at_unix_timestamp)) || "-",
    };
    AllTitles.push(dataSet);
  });
  return AllTitles;
};

export const imageTypes = ["jpeg", "png", "gif", "bmp", "tiff", "svg", "raw", "webp", "heic", "ico", "pbm", "pgm", "ppm", "exif"];

export const convertDurationToTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time / 60) % 60);
  const seconds = Math.floor(time % 60);
  return hours === 0
    ? `${formatTime(minutes)}:${formatTime(seconds)}`
    : `${formatTime(hours)}:${formatTime(minutes)}:${formatTime(seconds)}`;
};

export const formatTime = (timeComponent) => {
  return timeComponent > 9 ? timeComponent.toString() : "0" + timeComponent;
};
