import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ListUser from "src/Pages/ListUser";
import ListCoin from "src/Pages/ListCoin";
import Setletment from "src/Pages/Setletment";
import ListCelebDonate from "src/Pages/ListCelebDonate";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { md: 3 } }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabHeader() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          // textColor="secondary"
          // indicatorColor="secondary"
          // aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "black",
            },
            "& .MuiTab-root": {
              font: "normal normal 600 18px/18px Pretendard",
              "&.Mui-selected": {
                color: "black",
              },
            },
          }}
        >
          <Tab label="셀러브리티 목록" {...a11yProps(0)} />
          <Tab label="코인 적립로그" {...a11yProps(1)} />
          <Tab label="정산관리" {...a11yProps(2)} />
          {/* <Tab label="정산" {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <ListUser />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ListCoin />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Setletment />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        <ListCelebDonate />
      </TabPanel> */}
    </Box>
  );
}
